import React from "react";
import styles from "./TermsOfService.module.scss";

const TermsOfService: React.FC = () => {
  return (
    <div className={styles.container}>
      <main className="site-main">
        <article className="post tag-agreement no-image single">
          <header className="post-header container medium">
            <h1 className={styles.postTitle}>服务条款</h1>
            <div className={styles.postMeta}>
              <div className={styles.postMetaContent}>
                <div className={styles.postDate}>
                  <time dateTime="2021-03-16">3月 16, 2021</time>
                </div>
                <div className={styles.postLength}>约 28 分钟读完</div>
              </div>
            </div>
          </header>
          <div
            className={`${styles.postContent} ${styles.kgCanvas} ${styles.uTextFormat}`}
          >
            <p>
              请仔细阅读《服务协议》(下称“本协议”)，本协议适用于用户访问和使用由WhiteCoins（以下简称“我们”）提供的任何服务，包括但不限于网站www.whitecoins.top
              （下称“WhiteCoins”或“本平台”）以及我们提供的移动应用客户端和钱包服务（统称为“本服务”）。
            </p>
            <p>
              用户在点击本平台的注册页面同意按钮并完成注册程序、获得本平台账号和密码时，即视为用户已知晓、理解并接受，同意本协议及本平台已经发布的或将来可能发布的所有的法律协议、操作规则及相关申明。所有规则、声明、说明为协议不可分割的一部分，与本协议正文具有同等法律效力。如果用户不同意受本协议的约束，请勿访问或使用本服务。请注意，WhiteCoins有权对本协议进行修改，恕不另行通知补发，请您定期查看本协议，因为您继续使用该平台将被视为是不可撤销的接受任何修订。本条款不涉及WhiteCoins用户与其他用户之间因数字资产交易而产生的法律关系及法律纠纷。为便利广大用户，本平台所有内容可能提供多个语言版本，若有冲突或遗漏等情况，以中文内容为准。
            </p>
            <p>
              <strong>
                <strong>重要提示：</strong>
              </strong>
              <br />
              我们在此特别提醒您：
              <br />
              1、数字资产本身不由任何金融机构或公司或本平台发行；
              <br />
              2、数字资产市场是全新的、未经确认的，而且可能不会增长；
              <br />
              3、数字资产主要由投机者大量使用，零售和商业市场使用相对较少，数字资产交易存在极高风险，其全天不间断交易，没有涨跌限制，价格容易受庄家、全球政府政策的影响而大幅波动；
              <br />
              4、如果本平台根据其单方判断认为您违反了本协议，或者根据您所在管辖区域的法律本平台提供的服务或者您使用本平台提供的服务的行为是非法的，我们有权随时暂停或终止您的账户，或者暂停或终止您使用本平台提供的服务或数字资产交易。
              禁止位于美国的任何人使用本平台提供的服务。
              <br />
              5、数字资产交易有极高风险，并不适合绝大部分人士。您了解和理解此投资有可能导致部分损失或全部损失，所以您应该以能承受的损失程度来决定投资的金额。您了解和理解数字资产会产生衍生风险，所以如有任何疑问，建议先寻求理财顾问的协助。此外，除了上述提及过的风险以外，还会有未能预测的风险存在。您应慎重考虑并用清晰的判断能力去评估自己的财政状况及上述各项风险而作出任何买卖数字资产的决定，并承担由此产生的全部损失，我们对此不承担任何责任。
            </p>
            <p>
              <strong>
                <strong>一、服务内容</strong>
              </strong>
            </p>
            <p>
              本平台为您提供数字货币资产交易网络平台服务及交易申诉仲裁的服务（包括但不限于数字资产交易等服务），本平台并不作为买家或卖家参与买卖数字资产行为本身；本平台不提供任何国家法定货币充入和提取的相关服务。
            </p>
            <p>1、用户提供的注册数据，用户同意：</p>
            <p>（1）提供合法、真实、准确的个人资料；</p>
            <p>
              （2）如有变动，及时更新用户数据。如果用户提供的注册数据不合法、不真实、不准确的，用户需承担因此引起的相应责任及后果，并且本平台保留终止用户使用本平台各项服务的权利。
            </p>
            <p>
              2、您有权在本平台浏览数字货币交易信息、有权通过本平台提交数字货币资产交易指令并完成数字货币资产交易。
            </p>
            <p>3、您有权使用本平台提供的数字货币钱包服务。</p>
            <p>4、本平台承诺为您提供的其他服务。</p>
            <p>
              <strong>
                <strong>二、服务规则</strong>
              </strong>
            </p>
            <p>您承诺遵守下列本平台服务规则：</p>
            <p>
              1、您应当遵守法律法规、规章、及政策要求的规定，保证账户中所有数字资产来源的合法性，不得在本平台或利用本平台服务从事非法或其他损害本平台或第三方权益的活动，如发送或接收任何违法、违规、侵犯他人权益的信息，发送或接收传销材料或存在其他危害的信息或言论，未经本平台授权使用或伪造本平台电子邮件题头信息等。
            </p>
            <p>
              2、您应当遵守法律法规并妥善使用和保管其本平台账号及登陆密码、资金密码、和其注册时绑定的手机号码、以及手机接收的手机验证码的安全。您对使用其本平台账号和登陆密码、资金密码、手机验证码进行的任何操作和后果承担全部责任。当您发现本平台账号、登陆密码、或资金密码、验证码被未经其授权的第三方使用，或存在其他账号安全问题时，应立即有效通知本平台，要求本平台暂停本平台账号的服务。本平台有权在合理时间内对您的该等请求采取行动，但本平台对在采取行动前已经产生的后果（包括但不限于您的任何损失）不承担任何责任。您在未经本平台同意的情况下不得将本平台账号以赠与、借用、租用、转让或其他方式处分给他人。
            </p>
            <p>
              3、您同意您对您的本平台的账号、密码下发生的所有活动（包括但不限于信息披露、发布信息、网上点击同意或提交各类规则协议、网上续签协议或购买服务等）承担责任。
            </p>
            <p>
              4、您在本平台进行数字资产交易时不得恶意干扰数字资产交易的正常进行、破坏交易秩序；不得以任何技术手段或其他方式干扰本平台的正常运行或干扰其他用户对本平台服务的使用；不得以虚构事实等方式恶意诋毁本平台的商誉。
            </p>
            <p>
              5、如您因网上交易与平台入驻广告方产生纠纷的，不得通过司法或行政以外的途径要求本平台提供相关资料。
            </p>
            <p>
              6、您在使用本平台提供的服务过程中，所产生的应纳税赋，以及一切硬件、软件、服务及其它方面的费用，均由您独自判断和承担。
            </p>
            <p>
              7、您应当遵守本平台不时发布和更新的本协议以及其他服务条款和操作规则，有权随时终止使用本平台提供的服务。
            </p>
            <p>
              <strong>
                <strong>三、交易规则</strong>
              </strong>
            </p>
            <p>
              本平台实行入驻广告方接单制，在您完全接受交易信息中包含的全部内容后方可点击按钮进行交易，您在下单后系统会自动为您匹配广告方进行交易撮合。您提交交易订单后，即意味您授权本平台撮合您的交易订单。
            </p>
            <p>1、交易过程中，在您未点击确认付款按钮前，您有权随时取消订单。</p>
            <p>
              2、您可以通过本平台交易明细查看相应的成交记录，回看自己的详细交易记录。
            </p>
            <p>
              3、如因您交易操作失误，出现成功付款却点击订单取消、汇款至其他广告方银行账户、超额付款等情况所导致的资产损失需自行承担，本平台概不负责。
            </p>
            <p>
              <strong>
                <strong>四、服务的提供、修改及终止</strong>
              </strong>
            </p>
            <p>
              我们保留不时修订本协议、并以网站公示的方式进行公告、不再单独通知您的权利，变更后的协议会在本协议首页标注变更时间，一经在网站公布，立即自动生效。您应不时浏览及关注本协议的更新变更时间及更新内容，如您不同意相关变更，应当立即停止使用本平台服务；您继续使用本平台服务，即表示您接受并同意经修订的协议的约束。
            </p>
            <p>
              1、用户在接受本平台各项服务的同时，同意接受本平台提供的各类信息服务。用户在此授权本平台可以向其账户、电子邮件、手机等发送商业信息。用户可进入本平台相关页面更改联系方式。
            </p>
            <p>
              2、本平台保留随时修改或中断服务而无需另行通知用户的权利，本平台有权行使修改或中断服务的权利，不需对用户或任何无直接关系的第三方负责。
              我们已采取合理措施确保网站资讯的准确性，但并不能保证其准确性程度，亦不会承担任何因本平台上的资讯或因未能链结互联网、传送或接收任何通知和信息时的延误或失败而直接或间接产生的损失。
            </p>
            <p>
              3、法律允许范围内，无论在以下何种情况下：信息网络设备维护、信息网络连接故障、计算机、通讯或其他系统的故障、电力故障、罢工、劳动争议、暴乱、起义、骚乱、生产力或生产数据不足、火灾、洪水、风暴、爆炸、战争、政府行为、司法行政机关的命令、其他不可抗力或第三方的不作为而造成的服务终止或服务延迟以及用户因此而遭受的损失，
              本平台不承担责任。
            </p>
            <p>
              4、本平台有权依据本协议约定注销您的本平台账号，本协议于账号注销之日终止。
            </p>
            <p>
              5、本平台有权依据本协议约定终止全部本平台服务，本协议于本平台全部服务终止之日终止。
            </p>
            <p>
              6、本协议终止后，您无权要求本平台继续向其提供任何服务或履行任何其他义务，包括但不限于要求本平台为您保留或向您披露其原本平台账号中的任何信息，向您或第三方转发任何其未曾阅读或发送过的信息等。
            </p>
            <p>7、本协议的终止不影响守约方向违约方要求其他责任的承担。</p>
            <p>
              <strong>
                <strong>五、用户信息的保密</strong>
              </strong>
            </p>
            <p>
              1、本协议所称之本平台用户信息是指符合法律、法规及相关规定，并符合下述范围的信息：
            </p>
            <p>（1）用户注册本平台时，向本平台提供的个人信息；</p>
            <p>
              （2）用户在使用本平台服务、参加本平台活动或访问网站网页时，本平台自动接收并记录的用户浏览器端数据，包括但不限于IP地址、网站Cookie中的数据及用户要求取用的网页记录；
            </p>
            <p>（3）本平台从商业伙伴处合法获取的用户个人信息；</p>
            <p>（4）其它本平台通过合法途径获取的用户个人信息。</p>
            <p>
              2、本平台承诺：未经法定原因或用户事先许可，本平台不会向任何第三方透露用户的密码、姓名、手机号码、证件号码等非公开信息。
            </p>
            <p>3、在下述法定情况下，用户的个人信息将会被部分或全部披露。</p>
            <p>（1）经用户同意向用户本人或其他第三方披露；</p>
            <p>
              （2）根据法律、法规等相关规定，或行政机构要求，向行政、司法机构或其他法律规定的第三方披露；
            </p>
            <p>（3）其他本平台根据法律、法规等相关规定进行的披露。</p>
            <p>
              <strong>
                <strong>六、用户权利</strong>
              </strong>
            </p>
            <p>1、用户的用户名、密码和安全性：</p>
            <p>（1）用户名不可重复注册，用户名一旦注册成功，不可更改。</p>
            <p>
              （2）用户一旦注册成功，成为本平台的用户，将得到用户名和密码，并对以此组用户名和密码登入系统后所发生的所有活动和事件负责，自行承担一切使用该用户名发布的言语、行为等而直接或间接导致的法律责任。
            </p>
            <p>
              （3）用户有义务妥善保管本平台账号、用户名和密码、短信验证码、谷歌验证码，用户将对用户名和密码、和谷歌密钥安全负全部责任。因用户原因导致用户名或密码、谷歌密钥泄露而造成的任何法律后果由用户本人负责，由于用户自身原因泄露这些信息导致的财产损失，本平台不负相关责任。由于本平台是P2P场外交易平台，登录密码、防钓鱼码等不得使用相同密码，否则会有安全隐患，相关责任由用户自身承担。
            </p>
            <p>
              （4）用户密码遗失的，可以通过注册电子邮箱发送的链接或通过手机验证码方式找回。验证登录后，可重置密码。用户若发现任何非法使用用户名或存在其他安全漏洞的情况，应立即告知本平台。
            </p>
            <p>
              （5）本平台不会向任何用户索取密码，不会让用户往任何非本平台交易中心里提供的帐户、钱包地址充值任何数字资产，请大家不要相信任何非本平台官方发布的诈骗信息，往非本平台交易中心提供的账户、钱包地址里充值数字资产造成的损失本平台概不负责。
            </p>
            <p>
              2、用户有权修改其账户个人中心、安全设置中各项可修改信息，自行选择录入介绍性文字，自行决定是否为非必填项的内容；用户在发布广告信息时，应反复核查再进行发布，如发布错误可在交易中心点击下架重新发布。如因个人发布信息（包含个人银行账户信息、联系方式等内容）错误而导致的交易纠纷，本平台概不负责。
            </p>
            <p>
              3、用户有权在本平台浏览比特币的信息详情以及交易信息并发表符合国家法律规定、符合本平台规则的文章及观点；
            </p>
            <p>
              4、用户有权根据本平台相关规定，获得本平台给与的返佣返现等奖励（如手续费按比例返现等）；
            </p>
            <p>
              5、用户有权按照本平台发布的活动规则参与本平台组织的各项线上、线下活动。
            </p>
            <p>6、用户有权查看其本平台账号下的信息。</p>
            <p>
              7、用户有权根据本平台平台规定，应用本平台提供的功能进行操作、享受本平台提供的其它各类服务。
            </p>
            <p>
              <strong>
                <strong>七、用户义务</strong>
              </strong>
            </p>
            <p>
              1、不得利用本平台危害国家安全、泄露国家秘密，不得侵犯国家社会集体的和公民的合法权益，不得利用本平台制作、复制和传播下列信息：
            </p>
            <p>（1）煽动抗拒、破坏宪法和法律、行政法规实施的；</p>
            <p>（2）煽动颠覆国家政权，推翻社会主义制度的；</p>
            <p>（3）煽动分裂国家、破坏国家统一的；</p>
            <p>（4）煽动民族仇恨、民族歧视，破坏民族团结的；</p>
            <p>（5）捏造或者歪曲事实，散布谣言，扰乱社会秩序的；</p>
            <p>
              （6）宣扬封建迷信、淫秽、色情、赌博、暴力、凶杀、恐怖、教唆犯罪的；
            </p>
            <p>
              （7）公然侮辱他人或者捏造事实诽谤他人的，或者进行其他恶意攻击的；
            </p>
            <p>（8）损害国家机关信誉的；</p>
            <p>（9）其他违反宪法和法律行政法规的；</p>
            <p>（10）进行商业广告行为的。</p>
            <p>
              2、用户不得通过任何手段恶意注册本平台账号，包括但不限于以牟利、炒作、套现等为目的多个账号注册。用户亦不得盗用其他用户账号。
              如用户违反上述规定，则本平台有权直接采取一切必要的措施，包括但不限于删除用户发布的内容、取消用户在网站获得的星级、荣誉以及虚拟财富，数字资产，暂停或查封用户账号，取消因违规所获利益，乃至通过诉讼形式追究用户法律责任等。
            </p>
            <p>
              3、禁止用户将本平台以任何形式作为从事各种非法活动的场所、平台或媒介。未经本平台的授权或许可，用户不得借用本站的名义从事任何商业活动，也不得以任何形式将本平台作为从事商业活动的场所、平台或媒介。
              如用户违反上述规定，则本平台有权直接采取一切必要的措施，包括但不限于删除用户发布的内容、取消用户在网站获得的星级、荣誉以及虚拟财富，数字资产，暂停或查封用户账号，取消因违规所获利益，乃至通过诉讼形式追究用户法律责任等。
            </p>
            <p>
              4、用户在本平台以各种形式发布的一切信息，均应符合国家法律法规等相关规定及网站相关规定，符合社会公序良俗，并不侵犯任何第三方主体的合法权益，否则用户自行承担因此产生的一切法律后果，且本平台因此受到的损失，有权向用户追偿。
            </p>
            <p>
              5、禁止用户使用本平台从事洗钱、走私、商业贿赂等一切非法交易活动或违法行为，若发现任何涉嫌非法交易或违法行为，本平台将采取各种可使用之手段，包括但不限于冻结账户，通知相关权力机关等，我们不承担由此产生的所有责任并保留向相关人士追究责任的权利。
            </p>
            <p>
              6、禁止用户使用本平台进行恶意操纵市场、不正当交易等一切不道德交易活动，若发现此类事件，本平台将对所有恶意操纵价格、恶意影响交易系统等不道德的行为采取警告、限制交易、关停账户等预防性地保护措施，我们不承担由此产生的所有责任并保留向相关人士追究责任的权利。
            </p>
            <p>
              <strong>
                <strong>八、拒绝担保与免责</strong>
              </strong>
            </p>
            <p>
              1、本平台作为“网络服务提供商”的第三方平台，不担保网站平台上的信息及服务能充分满足用户的需求。对于用户在接受本平台的服务过程中可能遇到的错误、侮辱、诽谤、不作为、淫秽、色情或亵渎事件，本平台不承担法律责任。
            </p>
            <p>
              2、基于互联网的特殊性，本平台也不担保服务不会受中断，对服务的及时性、安全性都不作担保，不承担非因本平台导致的责任。
              本平台力图使用户能对本平台进行安全访问和使用，但本平台不声明也不保证本平台或其服务器是不含病毒或其它潜在有害因素的；因此用户应使用业界公认的软件查杀任何自本平台下载文件中的病毒。
            </p>
            <p>
              3、本平台不对用户所发布信息的保存、修改、删除或储存失败等行为负责。对网站上的非因本平台故意所导致的排字错误、疏忽等不承担责任。
              本平台有权但无义务，改善或更正本平台任何部分之疏漏、错误。
            </p>
            <p>
              4、除非本平台以书面形式明确约定，本平台对于用户以任何方式（包括但不限于包含、经由、连接或下载）从本平台所获得的任何内容信息，包括但不限于广告等，不保证其准确性、完整性、可靠性；对于用户因本平台上的内容信息而购买、获取的任何产品、服务、信息或数据，本平台不承担责任。用户自行承担使用本平台信息内容所导致的风险。
            </p>
            <p>
              5、本平台内所有用户所发表的用户评论，仅代表用户个人观点，并不表示本平台赞同其观点或证实其描述，本平台不承担用户评论引发的任何法律责任。
            </p>
            <p>
              6、本平台有权删除本平台内各类不符合法律或协议规定的信息，而保留不通知用户的权利。
            </p>
            <p>
              7、所有发给用户的通告，本平台都将通过正式的页面公告、站内消息、电子邮件、客服电话、手机短信或常规的信件送达。任何非经本平台正规渠道获得的中奖、优惠、返利返佣等活动或信息，本平台不承担法律责任。
            </p>
            <p>
              8、本平台有权根据市场情况调整提现、转账、交易等手续费费率，有权决定活动推广期的终止。
            </p>
            <p>
              <strong>
                <strong>九、关于协议</strong>
              </strong>
            </p>
            <p>
              1、本服务协议是本平台与用户注册成为本平台用户，使用本平台服务之间的重要法律文件，本平台或者用户的任何其他书面或者口头意思表示与本协议不一致的，均应当以本协议为准。
            </p>
            <p>
              2、如果本协议的任何条款被视为不合法、无效或因任何原因而无法执行，则此等规定应视为可分割，不影响任何其它条款的法律效力。
            </p>
            <p>
              3、本协议于用户勾选本平台注册页面的网络服务条款并完成注册程序、获得本平台账号和密码时生效，对本平台和用户均具有约束力。
            </p>
            <p>
              4、因用户使用本平台而引起或与之相关的一切争议、权利主张或其它事项，均受新加坡共和国法律的管辖。
              用户和本平台发生争议的，应首先本着诚信原则通过协商加以解决。如果协商不成，可通过新加坡共和国当地法院提起诉讼。
            </p>
            <p>
              5、本协议全部内容均为根据新加坡共和国法律订立的合同，其成立、解释、内容及执行均适用新加坡共和国相关法律规定；任何因或与本协议约定的服务有关而产生的索赔或诉讼，都应依照新加坡共和国的法律进行管辖并加以解释和执行。为避免疑义，这一条款明确适用于任何针对我们的侵权索赔。任何针对我们或者是和我们有关的索赔或诉讼的管辖法院或诉讼地均在新加坡共和国。您无条件地获得在新加坡共和国法院进行诉讼和上诉的排他性的管辖权。您也无条件地同意与本协议款有关的争议或问题或产生的任何索赔请求和诉讼的发生地或法院均排他性得在新加坡共和国，如有本平台其他业务对管辖有专门约定从其约定。不方便法院的原则不适用于根据本服务条款的选择的法院。
            </p>
            <p>
              <strong>
                <strong>了解你的客户和反洗钱政策</strong>
              </strong>
            </p>
            <p>
              <strong>
                <strong>一、导言</strong>
              </strong>
            </p>
            <p>
              1.1
              我们保证审慎遵守“了解你的客户”和反洗钱相关的法律法规且不得故意违反该《了解你的客户和反洗钱政策》。在我们合理控制的范围内我们将采取必要的措施和技术为您提供安全的服务，尽可能使您免于遭受犯罪嫌疑人的洗钱行为带来的损失。
            </p>
            <p>
              1.2
              我们的了解你的客户和反洗钱政策是一个综合性的国际政策体系，包括您隶属的不同法律辖区的了解你的客户和反洗钱政策。我们健全合规的框架确保我们在本地和全球层面均符合监管要求和监管水平，并确保本平台持续性运行。
            </p>
            <p>
              <strong>
                <strong>二、了解你的客户和反洗钱政策如下：</strong>
              </strong>
            </p>
            <p>
              2.1
              颁布了解你的客户和反洗钱政策并时时更新以满足相应的法律法规规定的标准；
            </p>
            <p>
              2.2
              颁布和更新运行本平台的一些指导原则和规则，且我们的员工将按照该原则和规则的指导提供服务；
            </p>
            <p>
              2.3
              设计并完成内部监测和控制交易的程序，如以严格的手段验证身份，安排组建专业团队专门负责反洗钱工作；
            </p>
            <p>2.4 采用风险预防的方法对客户进行尽职调查和持续的监督;</p>
            <p>2.5 审查和定期检查已发生的交易;</p>
            <p>2.6 向主管当局报告可疑交易;</p>
            <p>
              2.7
              身份证明文件、地址证明文件和交易记录的证明文件将会维持至少六年，如被提交给监管部门，恕不另行通知您。
            </p>
            <p>2.8 整个交易过程中，信用卡被禁止使用。</p>
            <p>
              <strong>
                <strong>三、身份信息与核实确认</strong>
              </strong>
            </p>
            <p>3.1 身份信息</p>
            <p>
              3.1.1
              根据不同的司法管辖区的不同规定及不同的实体类型，我们收集的您的信息内容可能不一致，原则上将向注册的个人收集以下信息：
            </p>
            <p>
              ●个人基本信息：您的姓名，住址（及永久地址，如果不同）
              ，出生日期及国籍等可获得的其他情况。身份验证应该是根据官方或其他类似权威机构发放的文件，比如护照，身份证或其他不同的辖区要求的和引发的身份证明文件。您提供的地址将使用适当的方法进行验证，比如检查乘用交通工具的票据或利率票据或检查选民登记册等。
            </p>
            <p>
              ●有效的照片：在您注册之前，您须提供您将您的身份证件放在胸前的照片；
            </p>
            <p>●联系方式：电话/手机号码和/或有效的电子邮件地址。</p>
            <p>
              3.1.2
              如果您是一个公司或其他合法实体，我们将收集以下信息以确定您或信托帐户
            </p>
            <p>的最终受益人。</p>
            <p>
              ●公司注册、登记证；公司的章程与备忘录副本；公司的股权机构和所有权说明的详细证明材料，证明决定本平台账户的开立以及执行的授权委托人的董事会决议；按照要求需要提供的公司董事、大股东及本平台账户有权签字人的身份证明文件；该公司的主要营业地址，如果与公司的邮寄地址不同，提供邮寄地址。如果公司在本地的地址与它的主要营业地址不一致的，则被视为风险较高的客户，需要提交额外附加文件。
            </p>
            <p>
              ●根据不同的司法管辖区的不同规定及不同的实体类型，我们要求的其他认证和权威部门发布的文件以及我们认为必要的文件。
            </p>
            <p>
              3.1.3
              我们只接受英语版本或汉语版本的身份信息，如不是，请您将您的身份信息翻译成英文的版本并加以公证。
            </p>
            <p>3.2 确认核实</p>
            <p>3.2.1 我们要求您提供身份证明文件的全部页面内容。</p>
            <p>3.2.2 我们要求您提供您将您的身份证明文件放在您胸前的照片。</p>
            <p>
              3.2.3
              证明文件的副本一般应核和原始凭证进行核对。然而，如果某个可信赖的合适的认证人可证明该副本文件是原始文件准确而全面的复制的，该副本可接受。这样的认证人包括大使、司法委员、地方治安官等。
            </p>
            <p>
              3.2.4
              对识别最终受益人和账户控制权的要求是确定哪些个人最终所有或控制直接客户，和/或确定正在进行的交易是由他人代为执行。如果是企业，则大股东的身份（例如那些持有10％或以上的投票权益）应被验证。一般，持股25
              ％会被认定为正常风险内，其股东身份须验证；持股10%或拥有更多的投票权或股票时被认定为高风险的情况，股东身份须加以验证。
            </p>
            <p>
              <strong>
                <strong>四、监控交易</strong>
              </strong>
            </p>
            <p>
              4.1
              我们根据安全性和实际交易情况时时设置和调整日常交易和提币最高限额;
            </p>
            <p>
              4.2
              如果交易频繁集中发生在某个注册用户或存在超乎合理的情况，我们的专业团队将评估并决定他们是否可疑;
            </p>
            <p>
              4.3
              我们凭借自身的判断认定为可疑交易的情况，我们可能会采取暂停该交易、拒绝该交易等限制性措施，甚至如果可能将尽快逆转该交易，同时向主管部门报告，但不会通知您;
            </p>
            <p>
              4.4
              我们保留拒绝来自于不符合国际反洗钱标准辖区的人或可被视为政治公众人物的人的注册申请，我们保留随时暂停或终止根据我们自身判断为可疑交易的交易，但我们这样做并不违反对您的任何义务和责任。
            </p>
          </div>
        </article>
      </main>
    </div>
  );
};
export default TermsOfService;
