import React, { FC, useEffect } from "react";
import { Avatar, Typography, Button, Flex, Divider, notification } from "antd";
import { useTranslation } from "react-i18next";
import styles from "./SellerCard.module.scss";
import { OfferInfo } from "../../types/entity";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getBalance } from "../../store/account/accountSlice";

const { Text } = Typography;

const SellerCard: FC<OfferInfo> = (props: OfferInfo) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { walletInfo } = useAppSelector((state) => state.account);

  useEffect(() => {
    dispatch(getBalance());
  }, [dispatch]);

  const {
    price,
    available_amount,
    nickname,
    crypto_currency,
    payment_methods,
    fiat_currency,
    fiat_min_amount_per_order,
    fiat_max_amount_per_order,
    completed_order_quantity,
    completed_rate,
    avg_completed_time,
    side,
  } = props;

  const onBuy = () => {
    notification.info({
      message: t("pages.trade.card.loading"),
    });
  };

  const onSell = () => {
    const minSellAmount = Number(price) * Number(fiat_min_amount_per_order);
    const usdtBalance = walletInfo?.balances?.find(
      balance => balance.name === "USDT"
    );
    
    if (!usdtBalance?.balance) {
      notification.info({
        message: t("pages.trade.card.loading"),
      });
      return;
    }

    if (Number(usdtBalance.balance) < minSellAmount) {
      notification.info({
        message: t("pages.trade.card.minAmount", { amount: minSellAmount }),
      });
      return;
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.basicInfo}>
          <Flex>
            <Avatar size={20} />
            <Text strong style={{ marginLeft: 8 }}>
              {nickname}
            </Text>
          </Flex>
          <Text type="secondary" style={{ marginTop: 4 }}>
            {`${completed_order_quantity} ${t("pages.trade.card.orderCount")} · ${completed_rate}% ${t("pages.trade.card.completionRate")}`}
          </Text>
          <Text type="secondary">{`${avg_completed_time} ${t("pages.trade.card.avgTime")}`}</Text>
        </div>
        <div className={styles.priceInfo}>
          <Text strong style={{ fontSize: "18px" }}>
            {`${price} ${fiat_currency}`}
          </Text>
        </div>
        <div className={styles.amountInfo}>
          <Text type="secondary">
            {`${available_amount} ${crypto_currency}`}
          </Text>
          <Text type="secondary">
            {`${fiat_min_amount_per_order} - ${fiat_max_amount_per_order} ${fiat_currency}`}
          </Text>
        </div>
        <div className={styles.paymentInfo}>
          {payment_methods.map((payment_method) => (
            <Text key={payment_method} type="secondary">
              {payment_method}
            </Text>
          ))}
        </div>
        <div className={styles.actionButton}>
          {side === 1 ? (
            <Button type="primary" danger onClick={onBuy}>
              {t("pages.trade.card.buy")}
            </Button>
          ) : (
            <Button type="primary" onClick={onSell}>
              {t("pages.trade.card.sell")}
            </Button>
          )}
        </div>
      </div>
      <Divider />
    </div>
  );
};

export default SellerCard;
