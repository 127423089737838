import React, { FC } from "react";
import { Layout } from "antd";
import styles from "./BlankLayout.module.scss";
import { Outlet } from "react-router-dom";
import Header from "../components/header/Header";

const BlankLayout: FC = () => {
  return (
    <Layout>
      <Header />
      <Layout.Content className={styles.main}>
        <Outlet />
      </Layout.Content>
    </Layout>
  );
};

export default BlankLayout;
