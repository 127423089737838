import React, { FC } from "react";
import { useParams } from "react-router-dom";

const MerchantDetail: FC = () => {
  const { id = "" } = useParams();

  return <div>MerchantDetail {id}</div>;
};

export default MerchantDetail;
