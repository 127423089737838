import React, { useEffect } from "react";
import styles from "./Register.module.scss";
import { Space, Typography, Form, Input, Button, message } from "antd";
import {
  UserAddOutlined,
  LockOutlined,
  UserOutlined,
  SafetyOutlined,
  GiftOutlined,
} from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { LOGIN_PATHNAME } from "../../router";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  sendCode,
  setRegisterCountdown,
  initCountdowns,
  register,
} from "../../store/user/userSlice";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
const { Title } = Typography;

const Register: React.FC = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { registerCountdown } = useAppSelector((state) => state.user);
  const navigate = useNavigate();
  const { inviteCode } = useParams(); // 获取URL中的邀请码

  // 如果URL中有邀请码,自动填充
  useEffect(() => {
    if (inviteCode) {
      form.setFieldValue("invite_code", inviteCode);
    }
  }, [inviteCode, form]);

  // 初始化倒计时
  useEffect(() => {
    dispatch(initCountdowns());
  }, [dispatch]);

  // 添加倒计时效果
  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (registerCountdown > 0) {
      timer = setInterval(() => {
        dispatch(setRegisterCountdown(registerCountdown - 1));
      }, 1000);
    }
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [registerCountdown, dispatch]);

  const onFinish = async (values: {
    email: string;
    password: string;
    password2: string;
    code: string;
    invite_code?: string;
  }) => {
    try {
      await dispatch(
        register({
          email: values.email,
          password: values.password,
          password2: values.password2,
          code: values.code,
          invite_code: values.invite_code,
        }),
      ).unwrap();

      message.success(t("pages.register.form.success"));
      navigate("/login");
    } catch (error: any) {
      message.error(error);
    }
  };

  const handleSendCode = async () => {
    if (registerCountdown > 0) return;

    try {
      const email = form.getFieldValue("email");
      if (!email) {
        message.error(t("pages.register.form.code.emailRequired"));
        return;
      }
      await dispatch(sendCode({ email, isLogin: false })).unwrap();
      message.success(t("pages.register.form.code.sendSuccess"));
    } catch (error) {
      message.error(t("pages.register.form.code.sendError"));
    }
  };

  return (
    <div className={styles.container}>
      <section>
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span>
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span>
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span>
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span>
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span>
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span>
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span>
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span>
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span>
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span>
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span>
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span>
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span>
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span>
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span>
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span>
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span>
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span>
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span>
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span>
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span>
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span>
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span>
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span>
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span>
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span>
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span>
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span>
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span>
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span>
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span>
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span>
        <span></span> <span></span> <span></span> <span></span> <span></span>
        <div className={styles.register}>
          <div className={styles.content}>
            <Title level={2}>{t("pages.register.title")}</Title>
            <Form
              layout="vertical"
              size="large"
              form={form}
              onFinish={onFinish}
            >
              <Form.Item
                label={t("pages.register.form.email.label")}
                name="email"
                rules={[
                  {
                    required: true,
                    message: t("pages.register.form.email.required"),
                  },
                  {
                    type: "email",
                    message: t("pages.register.form.email.invalid"),
                  },
                ]}
              >
                <Input
                  className={styles.input}
                  prefix={<UserOutlined />}
                  placeholder={t("pages.register.form.email.placeholder")}
                />
              </Form.Item>
              <Form.Item
                label={t("pages.register.form.code.label")}
                name="code"
                rules={[
                  {
                    required: true,
                    message: t("pages.register.form.code.required"),
                  },
                  {
                    pattern: /^[0-9]{6}$/,
                    message: t("pages.register.form.code.invalid"),
                  },
                ]}
              >
                <Space>
                  <Input
                    className={styles.input}
                    prefix={<SafetyOutlined />}
                    maxLength={6}
                    placeholder={t("pages.register.form.code.placeholder")}
                  />
                  <Button
                    onClick={handleSendCode}
                    disabled={registerCountdown > 0}
                  >
                    {registerCountdown > 0
                      ? t("pages.register.form.code.retryAfter", {
                          count: registerCountdown,
                        })
                      : t("pages.register.form.code.getCode")}
                  </Button>
                </Space>
              </Form.Item>
              <Form.Item
                label={t("pages.register.form.password.label")}
                name="password"
                rules={[
                  {
                    required: true,
                    message: t("pages.register.form.password.required"),
                  },
                ]}
              >
                <Input.Password
                  className={styles.input}
                  prefix={<LockOutlined />}
                  placeholder={t("pages.register.form.password.placeholder")}
                />
              </Form.Item>
              <Form.Item
                label={t("pages.register.form.confirm.label")}
                name="password2"
                dependencies={["password"]}
                rules={[
                  {
                    required: true,
                    message: t("pages.register.form.confirm.required"),
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(
                          new Error(t("pages.register.form.confirm.mismatch")),
                        );
                      }
                    },
                  }),
                ]}
              >
                <Input.Password
                  className={styles.input}
                  prefix={<LockOutlined />}
                  placeholder={t("pages.register.form.confirm.placeholder")}
                />
              </Form.Item>
              <Form.Item
                label={t("pages.register.form.inviteCode.label")}
                name="invite_code"
              >
                <Input
                  className={styles.input}
                  prefix={<GiftOutlined />}
                  placeholder={t("pages.register.form.inviteCode.placeholder")}
                />
              </Form.Item>
              <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
                <Space>
                  <Button type="primary" htmlType="submit">
                    {t("pages.register.form.submit")}
                  </Button>
                  <Link to={LOGIN_PATHNAME}>
                    {t("pages.register.form.login")}
                  </Link>
                </Space>
              </Form.Item>
            </Form>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Register;
