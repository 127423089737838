import React, { useEffect } from "react";
import { Card, Typography, Input, message } from "antd";
import {
  CopyOutlined,
  GiftOutlined,
  UserOutlined,
  TrophyOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import styles from "./Referral.module.scss";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { getInviteCode } from "../../store/user/userSlice";
const { Text, Title } = Typography;

const Referral: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { inviteCode } = useAppSelector((state) => state.user);

  useEffect(() => {
    dispatch(getInviteCode());
  }, [dispatch]);

  const copyLink = () => {
    navigator.clipboard.writeText(
      `https://www.whitecoins.top/join/${inviteCode?.code}`
    );
    message.success(t("common.copySuccess"));
  };

  const copyCode = () => {
    navigator.clipboard.writeText(inviteCode?.code || "");
    message.success(t("common.copySuccess"));
  };

  return (
    <div className={styles.container}>
      <Card bordered={false} className={styles.mainCard}>
        <div className={styles.header}>
          <Title level={2}>{t("pages.referral.header.title")}</Title>
          <Title level={2}>{t("pages.referral.header.reward")}</Title>
        </div>

        <div className={styles.inviteSection}>
          <div className={styles.inputGroup}>
            <Input
              addonBefore={<Text>{t("pages.referral.invite.link.label")}</Text>}
              value={`https://www.whitecoins.top/join/${inviteCode?.code}`}
              size="large"
              readOnly
              suffix={
                <CopyOutlined className={styles.copyIcon} onClick={copyLink} />
              }
            />
          </div>
          <div className={styles.inputGroup}>
            <Input
              addonBefore={<Text>{t("pages.referral.invite.code.label")}</Text>}
              value={inviteCode?.code || "********"}
              size="large"
              readOnly
              suffix={
                <CopyOutlined className={styles.copyIcon} onClick={copyCode} />
              }
            />
          </div>
        </div>
      </Card>

      <Card className={styles.explain}>
        <div className={styles.stepsSection}>
          <Title level={3}>{t("pages.referral.steps.title")}</Title>
          <div className={styles.steps}>
            <div className={styles.step}>
              <UserOutlined className={styles.stepIcon} />
              <Text strong>{t("pages.referral.steps.invite.title")}</Text>
              <Text type="secondary">
                {t("pages.referral.steps.invite.desc")}
              </Text>
            </div>
            <div className={styles.step}>
              <TrophyOutlined className={styles.stepIcon} />
              <Text strong>{t("pages.referral.steps.complete.title")}</Text>
              <Text type="secondary">
                {t("pages.referral.steps.complete.desc")}
              </Text>
            </div>
            <div className={styles.step}>
              <GiftOutlined className={styles.stepIcon} />
              <Text strong>{t("pages.referral.steps.claim.title")}</Text>
              <Text type="secondary">
                {t("pages.referral.steps.claim.desc")}
              </Text>
            </div>
          </div>
        </div>

        <div className={styles.rulesSection}>
          <Title level={3}>{t("pages.referral.rules.title")}</Title>
          <ol className={styles.rules}>
            <li>{t("pages.referral.rules.list.1")}</li>
            <li>{t("pages.referral.rules.list.2")}</li>
            <li>{t("pages.referral.rules.list.3")}</li>
            <li>{t("pages.referral.rules.list.4")}</li>
            <li>{t("pages.referral.rules.list.5")}</li>
          </ol>
        </div>
      </Card>
    </div>
  );
};

export default Referral;
