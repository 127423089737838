import styles from "./Login.module.scss";
import React, { useEffect } from "react";
import { Button, Form, Input, message, Space, Typography } from "antd";
import { LockOutlined, SafetyOutlined, UserOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { HOME_PATHNAME, REGISTER_PATHNAME } from "../../router";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  initCountdowns,
  login,
  sendCode,
  setLoginCountdown,
} from "../../store/user/userSlice";

const { Title } = Typography;

const Login: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { loading, error, loginCountdown } = useAppSelector(
    (state) => state.user,
  );

  useEffect(() => {
    dispatch(initCountdowns());
  }, [dispatch]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (loginCountdown > 0) {
      timer = setTimeout(
        () => dispatch(setLoginCountdown(loginCountdown - 1)),
        1000,
      );
    }
    return () => clearTimeout(timer);
  }, [loginCountdown, dispatch]);

  const onFinish = async (values: any) => {
    const { email, password, code } = values || {};
    try {
      await dispatch(login({ email, password, code })).unwrap();
      message.success(t("pages.login.form.success"));
      navigate(HOME_PATHNAME);
    } catch (error: any) {
      message.error(error);
    }
  };

  const handleSendCode = async () => {
    try {
      const email = form.getFieldValue("email");
      if (!email) {
        message.error(t("pages.login.form.code.emailRequired"));
        return;
      }
      await dispatch(sendCode({ email, isLogin: true })).unwrap();
      message.success(t("pages.login.form.code.sendSuccess"));
    } catch (error) {
      message.error(t("pages.login.form.code.sendError"));
    }
  };

  return (
    <div className={styles.container}>
      <section>
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span>
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span>
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span>
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span>
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span>
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span>
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span>
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span>
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span>
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span>
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span>
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span>
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span>
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span>
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span>
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span>
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span>
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span>
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span>
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span>
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span>
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span>
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span>
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span>
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span>
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span>
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span>
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span>
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span>
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span>
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span>
        <span></span> <span></span> <span></span> <span></span> <span></span>{" "}
        <span></span> <span></span> <span></span>
        <span></span> <span></span> <span></span> <span></span> <span></span>
        <div className={styles.login}>
          <div className={styles.content}>
            <Title level={2}>{t("pages.login.title")}</Title>
            <Form
              form={form}
              layout="vertical"
              size="large"
              onFinish={onFinish}
            >
              <Form.Item
                label={t("pages.login.form.email.label")}
                name="email"
                rules={[
                  {
                    required: true,
                    message: t("pages.login.form.email.required"),
                  },
                  {
                    type: "email",
                    message: t("pages.login.form.email.invalid"),
                  },
                ]}
              >
                <Input
                  className={styles.input}
                  prefix={<UserOutlined />}
                  placeholder={t("pages.login.form.email.placeholder")}
                />
              </Form.Item>
              <Form.Item
                label={t("pages.login.form.code.label")}
                name="code"
                rules={[
                  {
                    required: true,
                    message: t("pages.login.form.code.required"),
                  },
                  {
                    pattern: /^[0-9]{6}$/,
                    message: t("pages.login.form.code.invalid"),
                  },
                ]}
              >
                <Space>
                  <Input
                    className={styles.input}
                    prefix={<SafetyOutlined />}
                    maxLength={6}
                    placeholder={t("pages.login.form.code.placeholder")}
                  />
                  <Button
                    onClick={handleSendCode}
                    disabled={loginCountdown > 0}
                  >
                    {loginCountdown > 0
                      ? t("pages.login.form.code.retryAfter", {
                          count: loginCountdown,
                        })
                      : t("pages.login.form.code.getCode")}
                  </Button>
                </Space>
              </Form.Item>
              <Form.Item
                label={t("pages.login.form.password.label")}
                name="password"
                rules={[
                  {
                    required: true,
                    message: t("pages.login.form.password.required"),
                  },
                ]}
              >
                <Input.Password
                  className={styles.input}
                  prefix={<LockOutlined />}
                  placeholder={t("pages.login.form.password.placeholder")}
                />
              </Form.Item>
              <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
                <Space>
                  <Button type="primary" htmlType="submit" loading={loading}>
                    {t("pages.login.form.submit")}
                  </Button>
                  <Link to={REGISTER_PATHNAME}>
                    {t("pages.login.form.register")}
                  </Link>
                </Space>
              </Form.Item>
            </Form>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Login;
