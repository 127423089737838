import request from "./request";
import { Paging } from "../types/api";
import {
  OfferInfo,
  WalletInfo,
  SecurityInfo,
  PaymentMethodInfo,
  OrderInfo,
  PaymentMethodDto,
  InviteCode,
  InvitedUser,
} from "../types/entity";

export default {
  code(username: string) {
    return request.post("/api/user/email/code", { email: username });
  },

  login(email: string, password: string, code: string) {
    return request.post<string>("/api/user/login/email", {
      email,
      password,
      code,
    });
  },

  register(
    email: string,
    password: string,
    password2: string,
    code: string,
    invite_code?: string,
  ) {
    return request.post("/api/user/register", {
      email,
      password,
      password2,
      code,
      invite_code,
    });
  },

  getOfferList(page: number, size: number, side: number) {
    return request.get<Paging<OfferInfo>>("/api/offer/getOfferList", {
      page,
      size,
      side,
    });
  },

  getOrderList(page: number, size: number, order_status: number) {
    return request.get<Paging<OrderInfo>>("/api/order/getOrderList", {
      page,
      size,
      order_status,
    });
  },

  getOfferDetail(id: string) {
    return request.get<OfferInfo>(`/api/offer/${id}`);
  },

  getBalance() {
    return request.get<WalletInfo>("/api/account/balance");
  },

  getSecurity() {
    return request.get<SecurityInfo>("/api/account/security");
  },

  getPaymentMethods() {
    return request.get<PaymentMethodInfo[]>(
      "/api/payment-method/getPaymentMethodList",
    );
  },

  addPaymentMethod(paymentMethod: PaymentMethodDto) {
    return request.post("/api/payment-method/add", paymentMethod);
  },

  deletePaymentMethod(id: string) {
    return request.delete("/api/payment-method/delete", { id });
  },

  getInviteCode() {
    return request.get<InviteCode>("/api/user/getInviteCode");
  },

  getInvitedUsers() {
    return request.get<InvitedUser[]>("/api/user/getInvitedUsers");
  },
};
