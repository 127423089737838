import React, { FC } from "react";
import { Outlet } from "react-router-dom";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import { Layout } from "antd";
import styles from "./MainLayout.module.scss";

const MainLayout: FC = () => {
  return (
    <Layout>
      <Header />
      <Layout.Content className={styles.main}>
        <Outlet />
      </Layout.Content>
      <Footer />
    </Layout>
  );
};

export default MainLayout;
