import React, { useEffect } from "react";
import "./App.css";
import { RouterProvider } from "react-router-dom";
import router from "./router";
import { restoreToken } from "./store/user/userSlice";
import { useAppDispatch } from "./store/hooks";

function App() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(restoreToken());
  }, [dispatch]);

  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
