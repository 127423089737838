import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../services/api";
import { WalletInfo, SecurityInfo } from "../../types/entity";

interface AccountState {
  loading: boolean;
  error: string | null;
  walletInfo: WalletInfo;
  securityInfo: SecurityInfo;
}

const initialState: AccountState = {
  loading: false,
  error: null,
  walletInfo: {
    total_balance: 0,
    balances: [],
  },
  securityInfo: {
    email: "",
    email_verified: false,
    phone: "",
    phone_verified: false,
    login_auth_app_switch: false,
    trade_auth_app_switch: false,
    have_trade_password: false,
  },
};

/* 获取余额 */
export const getBalance = createAsyncThunk(
  "account/getBalance",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.getBalance();
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || "获取余额失败");
    }
  }
);

/* 获取安全信息 */
export const getSecurity = createAsyncThunk(
  "account/getSecurity",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.getSecurity();
      return response;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "获取安全信息失败"
      );
    }
  }
);

export const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // 获取余额
    builder.addCase(getBalance.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getBalance.fulfilled, (state, action) => {
      state.loading = false;
      state.walletInfo = action.payload;
    });
    builder.addCase(getBalance.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    // 获取安全信息
    builder.addCase(getSecurity.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getSecurity.fulfilled, (state, action) => {
      state.loading = false;
      state.securityInfo = action.payload;
    });
    builder.addCase(getSecurity.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
  },
});

export default accountSlice.reducer;
