import React from "react";
import { Card, Typography, Button, Space } from "antd";
import {
  CheckCircleFilled,
  UserOutlined,
  MobileOutlined,
  MailOutlined,
  LockOutlined,
  CloseCircleFilled,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import styles from "./Security.module.scss";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { getSecurity } from "../../store/account/accountSlice";

const { Text, Title } = Typography;

const Security: React.FC = () => {
  const { t } = useTranslation();
  const { securityInfo } = useAppSelector((state) => state.account);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(getSecurity());
  }, [dispatch]);

  return (
    <div className={styles.container}>
      {/* 安全检查概览 */}
      <Card bordered={false}>
        <Title level={3}>{t("pages.security.overview.title")}</Title>
        <Space className={styles.tags}>
          <Space>
            <CheckCircleFilled className={styles.checkIcon} />
            <span>{t("pages.security.overview.tags.2fa")}</span>
          </Space>
          <Space>
            <CheckCircleFilled className={styles.checkIcon} />
            <span>{t("pages.security.overview.tags.kyc")}</span>
          </Space>
        </Space>
      </Card>

      {/* 安全设置详情 */}
      <Card bordered={false} className={styles.detailCard}>
        <Title level={3}>{t("pages.security.detail.title")}</Title>

        <div className={styles.securityList}>
          <div className={styles.securityItem}>
            <MailOutlined className={styles.icon} />
            <div className={styles.content}>
              <div className={styles.label}>{t("pages.security.detail.items.email.label")}</div>
              <div className={styles.desc}>{t("pages.security.detail.items.email.desc")}</div>
            </div>
            <Space>
              {securityInfo.email_verified ? (
                <div>
                  <CheckCircleFilled className={styles.verifiedIcon} />
                  <Text type="secondary">{securityInfo.email}</Text>
                </div>
              ) : (
                <div>
                  <CloseCircleFilled className={styles.unverifiedIcon} />
                  <Text type="secondary">{t("pages.security.detail.items.email.status.unverified")}</Text>
                </div>
              )}
              {/*<Button>{t("pages.security.detail.items.email.action")}</Button>*/}
            </Space>
          </div>

          <div className={styles.securityItem}>
            <MobileOutlined className={styles.icon} />
            <div className={styles.content}>
              <div className={styles.label}>{t("pages.security.detail.items.phone.label")}</div>
              <div className={styles.desc}>{t("pages.security.detail.items.phone.desc")}</div>
            </div>
            <Space>
              {securityInfo.phone_verified ? (
                <div>
                  <CheckCircleFilled className={styles.verifiedIcon} />
                  <Text type="secondary">{securityInfo.phone}</Text>
                </div>
              ) : (
                <div>
                  <CloseCircleFilled className={styles.unverifiedIcon} />
                  <Text type="secondary">{t("pages.security.detail.items.phone.status.unverified")}</Text>
                </div>
              )}
              {/*<Button>{t("pages.security.detail.items.phone.action")}</Button>*/}
            </Space>
          </div>

          <div className={styles.securityItem}>
            <LockOutlined className={styles.icon} />
            <div className={styles.content}>
              <div className={styles.label}>{t("pages.security.detail.items.password.label")}</div>
              <div className={styles.desc}>{t("pages.security.detail.items.password.desc")}</div>
            </div>
            {/*<Button>{t("pages.security.detail.items.password.action")}</Button>*/}
          </div>
        </div>
      </Card>
    </div>
  );
};

export default Security;
