import React, { FC, useEffect } from "react";
import styles from "../trade/Trade.module.scss";
import { Card, Segmented } from "antd";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Orders: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [orderType, setOrderType] = React.useState<string | number>("new");

  useEffect(() => {
    if (pathname.startsWith("/orders/new")) {
      setOrderType("new");
    } else if (pathname.startsWith("/orders/completed")) {
      setOrderType("completed");
    } else {
      navigate("/orders/new");
    }
  }, [pathname]);
  
  function handleSegmentChange(value: string | number) {
    setOrderType(value);
    if (value === "new") {
      navigate("/orders/new");
    } else {
      navigate("/orders/completed");
    }
  }

  return (
    <Card className={styles.container}>
      <div className={styles.tabs}>
        <Segmented
          size="large"
          style={{ marginBottom: 8 }}
          value={orderType}
          onChange={(value) => handleSegmentChange(value)}
          options={[
            { label: t("pages.orders.tabs.new"), value: "new" },
            { label: t("pages.orders.tabs.completed"), value: "completed" },
          ]}
        />
      </div>
      <Outlet />
    </Card>
  );
};

export default Orders;
