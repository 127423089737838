import React, { FC, useEffect, useState } from "react";
import { Pagination, Empty } from "antd";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import {
  LIST_PAGE_SIZE,
  LIST_PAGE_PARAM_KEY,
  LIST_PAGE_SIZE_PARAM_KEY,
} from "../../constants";

type PropsType = {
  total: number;
};

const Paging: FC<PropsType> = (props) => {
  const { total } = props;
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(LIST_PAGE_SIZE);

  // 从 url 参数中找到 page pageSize ，并且同步到 Pagination 组件中
  const [searchParams] = useSearchParams();
  useEffect(() => {
    const page = parseInt(searchParams.get(LIST_PAGE_PARAM_KEY) || "") || 1;
    setCurrent(page);
    const pageSize =
      parseInt(searchParams.get(LIST_PAGE_SIZE_PARAM_KEY) || "") ||
      LIST_PAGE_SIZE;
    setPageSize(pageSize);
  }, [searchParams]);

  // 当 page pageSize 改变时，跳转页面（改变 url 参数）
  const navigate = useNavigate();
  const { pathname } = useLocation();

  function handlePageChange(page: number, pageSize: number) {
    searchParams.set(LIST_PAGE_PARAM_KEY, page.toString());
    searchParams.set(LIST_PAGE_SIZE_PARAM_KEY, pageSize.toString());

    navigate({
      pathname,
      search: searchParams.toString(), // 除了改变 page pageSize 之外，其他的 url 参数要带着
    });
  }

  if (total === 0) {
    return (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description="暂无数据"
        style={{ 
          margin: "40px 0",
          padding: "24px",
          backgroundColor: "#fff",
          borderRadius: "8px"
        }}
      />
    );
  }

  return (
    <Pagination
      current={current}
      pageSize={pageSize}
      total={total}
      onChange={handlePageChange}
    />
  );
};

export default Paging;
