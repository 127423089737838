import { Modal, Typography, Divider, Input, Button, message } from "antd";
import { CopyOutlined, QrcodeOutlined } from "@ant-design/icons";
import React from "react";
import styles from "./RechargeModal.module.scss";
import { useTranslation } from "react-i18next";
const { Title, Text } = Typography;

export type RechargeModalProps = {
  crypto_currency: string;
  network: string;
  address: string;
  title: string;
  show: boolean;
  onOk: VoidFunction;
  onCancel: VoidFunction;
};

const RechargeModal: React.FC<RechargeModalProps> = ({
  crypto_currency,
  network,
  address,
  title,
  show,
  onOk,
  onCancel,
}) => {
  const { t } = useTranslation();

  const handleCopy = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      message.success(t("pages.wallet.notifications.copySuccess"));
    } catch (err) {
      message.error(t("pages.wallet.notifications.copyFailed"));
    }
  };

  return (
    <Modal
      title={<Title level={4}>{title}</Title>}
      open={show}
      onOk={onOk}
      onCancel={onCancel}
      width={480}
      footer={null}
    >
      <div className={styles.content}>
        <div className={styles.warning}>
          <Text type="warning">
            {t("pages.wallet.modal.recharge.tips.content.0")}
          </Text>
        </div>

        <div className={styles.item}>
          <Text type="secondary">
            {t("pages.wallet.table.columns.currency")}
          </Text>
          <Text strong>{crypto_currency}</Text>
        </div>

        <Divider />

        <div className={styles.item}>
          <Text type="secondary">
            {t("pages.wallet.modal.recharge.network")}
          </Text>
          <Text strong>{network}</Text>
        </div>

        <Divider />

        <div className={styles.item}>
          <Text type="secondary">
            {t("pages.wallet.modal.recharge.address")}
          </Text>
          <div className={styles.address}>
            <Input.TextArea
              value={address}
              autoSize
              readOnly
              className={styles.addressInput}
            />
            <div className={styles.actions}>
              <Button
                type="text"
                icon={<CopyOutlined />}
                onClick={() => handleCopy(address)}
              >
                {t("pages.wallet.modal.recharge.copyAddress")}
              </Button>
              {/* <Button 
                type="text" 
                icon={<QrcodeOutlined />}
              >
                {t("pages.wallet.modal.recharge.showQRCode")}
              </Button> */}
            </div>
          </div>
        </div>

        <div className={styles.tips}>
          <Text type="secondary">
            {t("pages.wallet.modal.recharge.tips.content.0")}
          </Text>
          <Text type="secondary">
            {t("pages.wallet.modal.recharge.tips.content.1")}
          </Text>
          <Text type="secondary">
            {t("pages.wallet.modal.recharge.tips.content.2")}
          </Text>
          <Text type="secondary">
            {t("pages.wallet.modal.recharge.tips.content.3")}
          </Text>
        </div>
      </div>
    </Modal>
  );
};

export default RechargeModal;
