import { Form, Input, Modal, Radio, Select, message } from "antd";
import { PaymentMethodDto } from "../../types/entity";
import React from "react";
import { useAppDispatch } from "../../store/hooks";
import { addPaymentMethod } from "../../store/payment-method/paymentMethodSlice";
import { useTranslation } from "react-i18next";

export type PaymentMethodModalProps = {
  userId: string;
  title: string;
  show: boolean;
  onOk: VoidFunction;
  onCancel: VoidFunction;
};

const PaymentMethodModal: React.FC<PaymentMethodModalProps> = ({
  title,
  show,
  onOk,
  onCancel,
}: PaymentMethodModalProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const dispatch = useAppDispatch();

  const onFinish = async (values: PaymentMethodDto) => {
    try {
      await dispatch(
        addPaymentMethod({
          ...values,
        })
      ).unwrap();

      form.resetFields();
      onOk();
    } catch (error: any) {
      message.error(error.message || t("pages.paymentMethod.modal.error"));
    }
  };

  return (
    <Modal title={title} open={show} onOk={form.submit} onCancel={onCancel}>
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Form.Item<PaymentMethodDto>
          label={t("pages.paymentMethod.modal.form.type.label")}
          name="type"
          rules={[{ required: true, message: t("pages.paymentMethod.modal.form.type.required") }]}
        >
          <Select placeholder={t("pages.paymentMethod.modal.form.type.placeholder")}>
            {Object.keys(t("pages.paymentMethod.types", { returnObjects: true })).map(key => (
              <Select.Option key={key} value={key}>
                {t(`pages.paymentMethod.types.${key}`)}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item<PaymentMethodDto>
          label={t("pages.paymentMethod.modal.form.account.label")}
          name="account"
          rules={[{ required: true, message: t("pages.paymentMethod.modal.form.account.required") }]}
        >
          <Input placeholder={t("pages.paymentMethod.modal.form.account.placeholder")} />
        </Form.Item>
        <Form.Item<PaymentMethodDto>
          label={t("pages.paymentMethod.modal.form.accountHolder.label")}
          name="account_holder"
          rules={[{ required: true, message: t("pages.paymentMethod.modal.form.accountHolder.required") }]}
        >
          <Input placeholder={t("pages.paymentMethod.modal.form.accountHolder.placeholder")} />
        </Form.Item>
        <Form.Item<PaymentMethodDto>
          label={t("pages.paymentMethod.modal.form.enabled.label")}
          name="enabled"
          rules={[{ required: true, message: t("pages.paymentMethod.modal.form.enabled.required") }]}
        >
          <Radio.Group optionType="button" buttonStyle="solid">
            <Radio value="true">{t("pages.paymentMethod.modal.form.enabled.options.enabled")}</Radio>
            <Radio value="false">{t("pages.paymentMethod.modal.form.enabled.options.disabled")}</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item<PaymentMethodDto>
          label={t("pages.paymentMethod.modal.form.remark.label")}
          name="remark"
        >
          <Input.TextArea placeholder={t("pages.paymentMethod.modal.form.remark.placeholder")} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default PaymentMethodModal;
