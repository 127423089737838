import React from "react";
import { MenuProps } from "antd";
import { Dropdown } from "antd";
import { LANGUAGE_MAP } from "../../locales/useLocale";
import useLocale from "../../locales/useLocale";
import { LocalEnum } from "../../types/enum";
import { GlobalOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

type Locale = keyof typeof LocalEnum;

const LocalePicker: React.FC = () => {
  const { setLocale, locale } = useLocale();
  const { t } = useTranslation();

  const localeList: MenuProps["items"] = Object.values(LANGUAGE_MAP).map(
    (item) => ({
      key: item.locale,
      label: t(`common.language.${item.locale}`),
      className:
        locale === item.locale ? "ant-dropdown-menu-item-selected" : "",
    })
  );

  return (
    <Dropdown.Button
      menu={{
        items: localeList,
        onClick: (e) => setLocale(e.key as Locale),
        selectedKeys: [locale],
      }}
      trigger={["click"]}
      placement="bottom"
      icon={<GlobalOutlined />}
    >
      {t(`common.language.${locale}`)}
    </Dropdown.Button>
  );
};

export default LocalePicker;
