import React, { useEffect } from "react";
import { Card, Typography, Tabs, Table } from "antd";
import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { getInvitedUsers } from "../../store/user/userSlice";
import { InvitedUser } from "../../types/entity";
import styles from "./Rewards.module.scss";

const { Title } = Typography;
const { TabPane } = Tabs;

const Rewards: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { invitedUsers } = useAppSelector((state) => state.user);

  useEffect(() => {
    dispatch(getInvitedUsers());
  }, [dispatch]);

  const invitedUsersColumns = [
    {
      title: t("pages.rewards.table.columns.nickname"),
      dataIndex: "nickname",
      key: "nickname",
    },
    {
      title: t("pages.rewards.table.columns.email"),
      dataIndex: "email",
      key: "email",
    },
    {
      title: t("pages.rewards.table.columns.inviteTime"),
      dataIndex: "invite_time",
      key: "inviteTime",
    },
    {
      title: t("pages.rewards.table.columns.children"),
      dataIndex: "children",
      width: 250,
      render: (children: InvitedUser['children']) => 
        children?.length ? (
          <a>{children.length} {t("pages.rewards.table.columns.users")}</a>
        ) : (
          <span>{t("pages.rewards.table.columns.noInvite")}</span>
        ),
    },
  ];

  return (
    <div className={styles.container}>
      <Card bordered={false}>
        <div className={styles.header}>
          <Title level={3}>{t("pages.rewards.title")}</Title>
          <div className={styles.subtitle}>{t("pages.rewards.subtitle")}</div>
        </div>

        <div className={styles.content}>
          <Tabs defaultActiveKey="crypto">
            <TabPane tab={t("pages.rewards.tabs.crypto")} key="crypto">
              {/* <div className={styles.tableHeader}>
                <Text type="secondary" className={styles.rules}>
                  {t("pages.rewards.table.rules")}
                </Text>
              </div> */}
              <Table
                columns={invitedUsersColumns}
                dataSource={invitedUsers}
                pagination={{
                  current: 1,
                  total: invitedUsers.length,
                  pageSize: 10,
                }}
                expandable={{
                  rowExpandable: (record) => record.children?.length > 0,
                  expandIconColumnIndex: 3
                }}
              />
            </TabPane>
          </Tabs>
        </div>
      </Card>
    </div>
  );
};

export default Rewards;
