import React from "react";
import { Typography, Card, Row, Col, Space } from "antd";
import {
  GlobalOutlined,
  SafetyCertificateOutlined,
  TeamOutlined,
  TrophyOutlined,
} from "@ant-design/icons";
import styles from "./About.module.scss";
import { useTranslation } from "react-i18next";

const { Title, Paragraph, Text } = Typography;

const About: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      {/* 关于我们 */}
      <Card className={styles.section}>
        <Title level={2}>{t("pages.about.intro.title")}</Title>
        <div className={styles.introduction}>
          <Paragraph>
            {t("pages.about.intro.description")}
          </Paragraph>
          
          <Row gutter={[32, 32]} className={styles.features}>
            <Col xs={24} sm={12}>
              <Card className={styles.featureCard}>
                <SafetyCertificateOutlined className={styles.icon} />
                <Title level={4}>{t("pages.about.intro.features.security.title")}</Title>
                <Text>{t("pages.about.intro.features.security.desc")}</Text>
              </Card>
            </Col>
            <Col xs={24} sm={12}>
              <Card className={styles.featureCard}>
                <GlobalOutlined className={styles.icon} />
                <Title level={4}>{t("pages.about.intro.features.global.title")}</Title>
                <Text>{t("pages.about.intro.features.global.desc")}</Text>
              </Card>
            </Col>
          </Row>

          <div className={styles.stats}>
            <Row gutter={[32, 32]}>
              <Col xs={12} sm={6}>
                <Title level={3}>{t("pages.about.intro.stats.employees.number")}</Title>
                <Text>{t("pages.about.intro.stats.employees.label")}</Text>
              </Col>
              <Col xs={12} sm={6}>
                <Title level={3}>{t("pages.about.intro.stats.merchants.number")}</Title>
                <Text>{t("pages.about.intro.stats.merchants.label")}</Text>
              </Col>
              <Col xs={12} sm={6}>
                <Title level={3}>{t("pages.about.intro.stats.users.number")}</Title>
                <Text>{t("pages.about.intro.stats.users.label")}</Text>
              </Col>
              <Col xs={12} sm={6}>
                <Title level={3}>{t("pages.about.intro.stats.countries.number")}</Title>
                <Text>{t("pages.about.intro.stats.countries.label")}</Text>
              </Col>
            </Row>
          </div>
        </div>
      </Card>

      {/* 团队文化 */}
      <Card className={styles.section}>
        <Title level={2}>{t("pages.about.culture.title")}</Title>
        <div className={styles.culture}>
          <Row gutter={[32, 32]}>
            <Col xs={24} md={12}>
              <Card className={styles.cultureCard}>
                <div className={styles.imageWrapper}>
                  <img 
                    src="https://images.unsplash.com/photo-1522071820081-009f0129c71c?auto=format&fit=crop&w=800"
                    alt={t("pages.about.culture.cards.teamwork.alt")}
                  />
                </div>
                <Title level={3}>{t("pages.about.culture.cards.teamwork.title")}</Title>
                <Text>{t("pages.about.culture.cards.teamwork.desc")}</Text>
              </Card>
            </Col>
            <Col xs={24} md={12}>
              <Card className={styles.cultureCard}>
                <div className={styles.imageWrapper}>
                  <img 
                    src="https://images.unsplash.com/photo-1552664730-d307ca884978?auto=format&fit=crop&w=800"
                    alt={t("pages.about.culture.cards.faith.alt")}
                  />
                </div>
                <Title level={3}>{t("pages.about.culture.cards.faith.title")}</Title>
                <Text>{t("pages.about.culture.cards.faith.desc")}</Text>
              </Card>
            </Col>
          </Row>

          <div className={styles.vision}>
            <Title level={3}>{t("pages.about.culture.vision.title")}</Title>
            <Title level={4}>{t("pages.about.culture.vision.subtitle")}</Title>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default About;
