import axios, { AxiosError, AxiosResponse } from "axios";
import { Result } from "../types/api";
import storage from "../util/storage";
import { TOKEN_KEY } from "../store/user/userSlice";
import { message as AntMessage } from "antd";
import { LOGIN_PATHNAME } from "../router";

const instance = axios.create({
  timeout: 60 * 1000,
});

// 请求拦截
instance.interceptors.request.use(
  (config) => {
    const token = storage.get(TOKEN_KEY) || "";
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error: AxiosError) => {
    // 请求错误时做些什么
    return Promise.reject(error);
  },
);

// 响应拦截
instance.interceptors.response.use(
  (response: AxiosResponse<Result>) => {
    if (!response.data) throw new Error("Network Error!");
    console.log("网络请求成功", response);
    const respData: Result = response.data;
    if (response.config.responseType === "blob") return response;
    const { data, code, message } = respData;
    if (code != 0) {
      if (message) {
        AntMessage.error(message);
      }
      throw new Error(message);
    }
    return data;
  },
  (error: AxiosError) => {
    const { response, code } = error;
    // 处理网络错误
    if (!response) {
      AntMessage.error(
        code === "ECONNABORTED"
          ? "请求超时，请检查网络连接"
          : "网络异常，请检查网络连接",
      );
      return Promise.reject(new Error("Network Error!"));
    }
    const { status, data } = response;
    const msg = (data as any)?.message || "Server Error!";
    AntMessage.error(msg);
    if (status === 401) {
      // 添加延时确保消息显示
      setTimeout(() => {
        storage.remove(TOKEN_KEY);
        window.location.href = LOGIN_PATHNAME;
      }, 1000); // 延时1.5秒
    }
    return Promise.reject(new Error(msg));
  },
);

export default {
  get<T>(url: string, params?: object): Promise<T> {
    return instance.get(url, { params });
  },
  post<T>(url: string, params?: object): Promise<T> {
    return instance.post(url, params);
  },
  put<T>(url: string, params?: object): Promise<T> {
    return instance.put(url, params);
  },
  delete<T>(url: string, params?: object): Promise<T> {
    return instance.delete(url, { params });
  },
};
