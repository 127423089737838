import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Paging } from "../../types/api";
import { OrderInfo } from "../../types/entity";
import api from "../../services/api";

interface OrderState {
  loading: boolean;
  error: string | null;
  records: OrderInfo[];
  total: number;
  size: number;
  current: number;
}

const initialState: OrderState = {
  loading: false,
  error: null,
  records: [],
  total: 0,
  size: 20,
  current: 1,
};

export const getOrderList = createAsyncThunk(
  "order/getOrderList",
  async (params: { page: number; size: number; order_status: number }, { rejectWithValue }) => {
    try {
      const response = await api.getOrderList(params.page, params.size, params.order_status);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || "获取列表失败");
      }
    }
  );

export const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOrderList.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getOrderList.fulfilled, (state, action) => {
      const { records, total, size, current } = action.payload;
      state.loading = false;
      state.records = records;
      state.total = total;
      state.size = size;
      state.current = current;
    });
    builder.addCase(getOrderList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
  },
});

export default orderSlice.reducer;

