import React from "react";
import { Card, Typography, Button, Space } from "antd";
import {
  RocketOutlined,
  NotificationOutlined,
  ShopOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import styles from "./Apply.module.scss";

const { Title, Paragraph } = Typography;

const Apply: React.FC = () => {
  const { t } = useTranslation();

  const features = [
    {
      icon: <RocketOutlined />,
      title: t("pages.apply.features.quick.title"),
      desc: t("pages.apply.features.quick.desc")
    },
    {
      icon: <NotificationOutlined />,
      title: t("pages.apply.features.support.title"),
      desc: t("pages.apply.features.support.desc")
    },
    {
      icon: <ShopOutlined />,
      title: t("pages.apply.features.privilege.title"),
      desc: t("pages.apply.features.privilege.desc")
    }
  ];

  return (
    <div className={styles.container}>
      <Card className={styles.card}>
        <Space direction="vertical" align="center" size="large">
          <ShopOutlined className={styles.icon} />

          <Title level={2}>{t("pages.apply.title")}</Title>

          <Paragraph className={styles.description}>
            {t("pages.apply.description")}
          </Paragraph>

          <div className={styles.features}>
            {features.map((feature, index) => (
              <div key={index} className={styles.feature}>
                {feature.icon}
                <span>{feature.title}</span>
              </div>
            ))}
          </div>

          <Button
            type="primary"
            size="large"
            icon={<NotificationOutlined />}
            disabled
          >
            {t("pages.apply.button")}
          </Button>
        </Space>
      </Card>
    </div>
  );
};

export default Apply;
