import React, { useState } from "react";
import { Card, Typography, Button, Space, Empty, Spin, Divider, Popconfirm } from "antd";
import {
  AlipayCircleOutlined,
  WechatOutlined,
  BankOutlined,
  GlobalOutlined,
  DollarOutlined,
  CreditCardOutlined,
} from "@ant-design/icons";
import PaymentMethodModal, {
  PaymentMethodModalProps,
} from "./PaymentMethodModal";
import styles from "./PaymentMethod.module.scss";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { getPaymentMethods, deletePaymentMethod } from "../../store/payment-method/paymentMethodSlice";
import { PaymentMethodInfo } from "../../types/entity";
import type { PopconfirmProps } from "antd";
import { log } from "console";
import { useTranslation } from "react-i18next";


const { Text, Title } = Typography;
// 支付方式类型映射
const PaymentTypeMap: Record<string, { label: string; icon: React.ReactNode }> =
  {
    "0": {
      label: "银行转账",
      icon: <BankOutlined className={styles.paymentIcon} />,
    },
    "1": {
      label: "支付宝",
      icon: <AlipayCircleOutlined className={styles.paymentIcon} />,
    },
    "2": {
      label: "微信支付",
      icon: <WechatOutlined className={styles.paymentIcon} />,
    },
    "3": {
      label: "PayPal",
      icon: <DollarOutlined className={styles.paymentIcon} />,
    },
    "4": {
      label: "Wise(TransferWise)",
      icon: <GlobalOutlined className={styles.paymentIcon} />,
    },
    "5": {
      label: "Revolut",
      icon: <CreditCardOutlined className={styles.paymentIcon} />,
    },
    "6": {
      label: "Zelle(美国)",
      icon: <DollarOutlined className={styles.paymentIcon} />,
    },
    "7": {
      label: "UPI(印度)",
      icon: <GlobalOutlined className={styles.paymentIcon} />,
    },
    "8": {
      label: "PIX(巴西)",
      icon: <GlobalOutlined className={styles.paymentIcon} />,
    },
    "9": {
      label: "GCash(菲律宾)",
      icon: <DollarOutlined className={styles.paymentIcon} />,
    },
    "10": {
      label: "DANA(印尼)",
      icon: <GlobalOutlined className={styles.paymentIcon} />,
    },
    "11": {
      label: "PayNow(新加坡)",
      icon: <DollarOutlined className={styles.paymentIcon} />,
    },
  };

const PaymentMethod: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { paymentMethods, loading } = useAppSelector(
    (state) => state.paymentMethod
  );

  React.useEffect(() => {
    dispatch(getPaymentMethods());
  }, [dispatch]);

  const onDeletePaymentMethod = (id: string) => {
    console.log(id);
    dispatch(deletePaymentMethod(id));
  };

  const [paymentMethodModalProps, setPaymentMethodModalProps] =
    useState<PaymentMethodModalProps>({
      userId: "",
      title: t("pages.paymentMethod.modal.title"),
      show: false,
      onOk: () => {
        setPaymentMethodModalProps((prev) => ({ ...prev, show: false }));
      },
      onCancel: () => {
        setPaymentMethodModalProps((prev) => ({ ...prev, show: false }));
      },
    });

  const onAddPaymentMethod = () => {
    setPaymentMethodModalProps((prev) => ({
      ...prev,
      title: t("pages.paymentMethod.modal.title"),
      show: true,
    }));
  };

  const renderPaymentItem = (payment: PaymentMethodInfo) => {
    const paymentType = PaymentTypeMap[payment.type] || {
      label: t("pages.paymentMethod.types.unknown"),
      icon: <BankOutlined className={styles.paymentIcon} />,
    };
    return (
      <div key={payment.id} className={styles.paymentItem}>
        <div
          className={`${styles.paymentHeader} ${styles[`type${payment.type}`]}`}
        >
          {paymentType.icon}
          <Text className={styles.label}>{paymentType.label}</Text>
          <Space className={styles.actions}>
            {/* <Button type="link" size="small">
              编辑
            </Button> */}
            <Popconfirm
              title={t("pages.paymentMethod.item.actions.deleteConfirm")}
              onConfirm={() => onDeletePaymentMethod(payment.id)}
            >
              <Button type="link" danger>
                {t("pages.paymentMethod.item.actions.delete")}
              </Button>
            </Popconfirm>
          </Space>
        </div>
        <div className={styles.paymentContent}>
          <div className={styles.row}>
            <Text type="secondary">{t("pages.paymentMethod.item.name")}</Text>
            <Text>{payment.id}</Text>
          </div>
          <div className={styles.row}>
            <Text type="secondary">{t("pages.paymentMethod.item.account")}</Text>
            <Text>{payment.account}</Text>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <Card bordered={false}>
        <div className={styles.header}>
          <Title level={3} className={styles.title}>
            {t("pages.paymentMethod.title")}
          </Title>
          <Button type="primary" onClick={onAddPaymentMethod}>
            {t("pages.paymentMethod.addButton")}
          </Button>
        </div>

        <div className={styles.description}>
          <Text type="secondary">
            {t("pages.paymentMethod.description")}
          </Text>
        </div>

        <Divider />

        <div className={styles.paymentList}>
          {loading ? (
            <div className={styles.loading}>
              <Spin />
            </div>
          ) : paymentMethods.length > 0 ? (
            paymentMethods.map(renderPaymentItem)
          ) : (
            <Empty description={t("pages.paymentMethod.empty")} />
          )}
        </div>
      </Card>
      <PaymentMethodModal {...paymentMethodModalProps} />
    </div>
  );
};

export default PaymentMethod;
