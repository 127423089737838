import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { LocalEnum } from "../types/enum";
import storage from "../util/storage";

import en_US from "./lang/en_US";
import zh_CN from "./lang/zh_CN";

const defaultLng: string =
  (storage.get("i18nextLng") as string) || (LocalEnum.en_US as string);

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: defaultLng,
    fallbackLng: LocalEnum.en_US,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en_US: { translation: en_US },
      zh_CN: { translation: zh_CN },
    },
  });

export default i18n;
export const { t } = i18n;
