import React, { useState, useEffect } from "react";
import {
  Card,
  Typography,
  Button,
  Table,
  Avatar,
  Space,
  notification,
} from "antd";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import styles from "./Wallet.module.scss";
import { getBalance } from "../../store/account/accountSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import RechargeModal, { RechargeModalProps } from "./RechargeModal";

const { Title } = Typography;

interface BalanceItem {
  icon: string;
  full_name: string;
  name: string;
  balance: number;
  address: string;
}

const Wallet: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { walletInfo } = useAppSelector((state) => state.account);
  const [isHidden, setIsHidden] = React.useState(false);

  React.useEffect(() => {
    dispatch(getBalance());
  }, [dispatch]);

  const balanceColumns = [
    {
      title: t("pages.wallet.table.columns.currency"),
      key: "currency",
      render: (record: BalanceItem) => (
        <Space>
          <Avatar src={record.icon} size="small" />
          <div>
            <div>{record.name}</div>
            <div className={styles.fullName}>{record.full_name}</div>
          </div>
        </Space>
      ),
    },
    {
      title: t("pages.wallet.table.columns.balance"),
      dataIndex: "balance",
      key: "balance",
    },
  ];

  const onRecharge = () => {
    setRechargeModalProps((prev) => ({ ...prev, show: true }));
  };

  const onWithdraw = () => {
    notification.info({
      message: t("pages.wallet.notifications.noBalance"),
    });
  };

  const [rechargeModalProps, setRechargeModalProps] = useState<RechargeModalProps>({
    show: false,
    title: t("pages.wallet.modal.recharge.title"),
    crypto_currency: "",
    network: "",
    address: "",
    onOk: () => setRechargeModalProps((prev) => ({ ...prev, show: false })),
    onCancel: () => setRechargeModalProps((prev) => ({ ...prev, show: false })),
  });

  useEffect(() => {
    if (walletInfo?.balances?.length > 0) {
      const usdtBalance = walletInfo.balances.find(
        balance => balance.name === "USDT"
      );
      
      if (usdtBalance) {
        setRechargeModalProps(prev => ({
          ...prev,
          crypto_currency: "USDT",
          network: "TRC20",
          address: usdtBalance.address
        }));
      }
    }
  }, [walletInfo]);

  return (
    <div className={styles.container}>
      <Card className={styles.overview}>
        <div className={styles.header}>
          <Title level={3}>{t("pages.wallet.overview.title")}</Title>
          <Button
            type="text"
            icon={isHidden ? <EyeInvisibleOutlined /> : <EyeOutlined />}
            onClick={() => setIsHidden(!isHidden)}
          />
        </div>
        <div className={styles.balance}>
          <Title level={2}>
            {isHidden ? t("pages.wallet.overview.hidden") : walletInfo.total_balance}
            <span className={styles.unit}>{t("pages.wallet.overview.unit")}</span>
          </Title>
        </div>
        <div className={styles.tags}>
          <Button 
            type="primary" 
            onClick={onRecharge}
            disabled={!walletInfo?.balances?.find(balance => balance.name === "USDT")}
          >
            {t("pages.wallet.actions.recharge")}
          </Button>
          <Button onClick={onWithdraw}>
            {t("pages.wallet.actions.withdraw")}
          </Button>
        </div>
      </Card>

      <Card className={styles.assets}>
        <Table
          columns={balanceColumns}
          dataSource={walletInfo.balances}
          pagination={false}
        />
      </Card>
      <RechargeModal {...rechargeModalProps} />
    </div>
  );
};

export default Wallet;
