import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../services/api";
import { PaymentMethodInfo, PaymentMethodDto } from "../../types/entity";

interface PaymentMethodState {
  loading: boolean;
  error: string | null;
  paymentMethods: PaymentMethodInfo[];
}

const initialState: PaymentMethodState = {
  loading: false,
  error: null,
  paymentMethods: [],
};

/* 获取支付方式 */
export const getPaymentMethods = createAsyncThunk(
  "paymentMethod/getPaymentMethods",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.getPaymentMethods();
      return response;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "获取支付方式失败"
      );
    }
  }
);

/* 删除支付方式 */
export const deletePaymentMethod = createAsyncThunk(
  "paymentMethod/deletePaymentMethod",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await api.deletePaymentMethod(id);
      return response;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "删除支付方式失败"
      );
    }
  }
);

/* 添加支付方式 */
export const addPaymentMethod = createAsyncThunk(
  "paymentMethod/addPaymentMethod",
  async (paymentMethod: PaymentMethodDto, { rejectWithValue }) => {
    try {
      const response = await api.addPaymentMethod(paymentMethod);
      return response;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "添加支付方式失败"
      );
    }
  }
);

const paymentMethodSlice = createSlice({
  name: "paymentMethod",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPaymentMethods.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPaymentMethods.fulfilled, (state, action) => {
      state.loading = false;
      state.paymentMethods = action.payload;
    });
    builder.addCase(getPaymentMethods.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(addPaymentMethod.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addPaymentMethod.fulfilled, (state, action) => {
      state.loading = false;
      state.paymentMethods.push(action.payload as PaymentMethodInfo);
    });
    builder.addCase(addPaymentMethod.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(deletePaymentMethod.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deletePaymentMethod.fulfilled, (state, action) => {
      state.loading = false;
      state.paymentMethods = state.paymentMethods.filter(
        (paymentMethod) => paymentMethod.id !== action.payload
      );
    });
    builder.addCase(deletePaymentMethod.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
  },
});

export default paymentMethodSlice.reducer;
