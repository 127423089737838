import React from "react";
import { createBrowserRouter } from "react-router-dom";

import MainLayout from "../layouts/MainLayout";
import HomeLayout from "../layouts/HomeLayout";
import Home from "../pages/home/Home";
import Login from "../pages/login/Login";
import Orders from "../pages/orders/Orders";
import Trade from "../pages/trade/Trade";
import MerchantDetail from "../pages/merchant-detail/MerchantDetail";
import NotFound from "../pages/NotFound";
import SellList from "../pages/sell-list/SellList";
import Register from "../pages/register/Register";
import Rewards from "../pages/rewards/Rewards";
import Referral from "../pages/referral/Referral";
import OrderList from "../pages/orders/OrderList";
import Security from "../pages/security/Security";
import Kyc from "../pages/kyc/Kyc";
import Wallet from "../pages/wallet/Wallet";
import Express from "../pages/express/Express";
import PaymentMethod from "../pages/payment-method/PaymentMethod";
import BuyList from "../pages/buy-list/BuyList";
import Apply from "../pages/apply/Apply";
import About from "../pages/about/About";
import TermsOfService from "../pages/terms-of-service/TermsOfService";
import PrivacyPolicy from "../pages/privacy-policy/PrivacyPolicy";
import AuthGuard from "./AuthGuard";
import { Navigate } from "react-router-dom";
import BlankLayout from "../layouts/BlankLayout";
const router = createBrowserRouter([
  {
    path: "/",
    element: <HomeLayout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "about",
        element: <About />,
      },
      {
        path: "terms-of-service",
        element: <TermsOfService />,
      },
      {
        path: "privacy-policy",
        element: <PrivacyPolicy />,
      },
    ],
  },
  {
    path: "/",
    element: <BlankLayout />,
    children: [
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "register",
        element: <Register />,
      },
    ],
  },
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: "join/:inviteCode",
        element: <Register />,
      },
      {
        path: "express",
        element: (
          <AuthGuard>
            <Express />
          </AuthGuard>
        ),
      },
      {
        path: "trade",
        element: (
          <AuthGuard>
            <Trade />
          </AuthGuard>
        ),
        children: [
          { path: "sell", element: <SellList /> },
          { path: "buy", element: <Navigate to="/trade/sell" replace /> },
        ],
      },
      {
        path: "orders",
        element: (
          <AuthGuard>
            <Orders />
          </AuthGuard>
        ),
        children: [
          { path: "new", element: <OrderList /> },
          { path: "completed", element: <OrderList /> },
        ],
      },
      {
        path: "wallet",
        element: (
          <AuthGuard>
            <Wallet />
          </AuthGuard>
        ),
      },
      {
        path: "payment-method",
        element: (
          <AuthGuard>
            <PaymentMethod />
          </AuthGuard>
        ),
      },
      {
        path: "security",
        element: (
          <AuthGuard>
            <Security />
          </AuthGuard>
        ),
      },
      {
        path: "kyc",
        element: (
          <AuthGuard>
            <Kyc />
          </AuthGuard>
        ),
      },
      {
        path: "rewards",
        element: (
          <AuthGuard>
            <Rewards />
          </AuthGuard>
        ),
      },
      {
        path: "referral",
        element: (
          <AuthGuard>
            <Referral />
          </AuthGuard>
        ),
      },
      {
        path: "apply",
        element: (
          <AuthGuard>
            <Apply />
          </AuthGuard>
        ),
      },
      {
        path: "merchant/:id",
        element: (
          <AuthGuard>
            <MerchantDetail />
          </AuthGuard>
        ),
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
]);

export default router;

export const HOME_PATHNAME = "/";
export const LOGIN_PATHNAME = "/login";
export const REGISTER_PATHNAME = "/register";
