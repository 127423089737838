import React from "react";
import { Typography, Button, Space, Row, Col, Card } from "antd";
import {
  SafetyCertificateOutlined,
  ThunderboltOutlined,
  GlobalOutlined,
  RocketOutlined,
} from "@ant-design/icons";
import styles from "./Home.module.scss";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const { Title, Text } = Typography;

const Home: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      {/* Hero Section */}
      <div className={styles.hero}>
        <div className={styles.heroContent}>
          <Title className={styles.title}>
            {t("pages.home.hero.titlePrefix")}{" "}
            <span className={styles.highlight}>
              {t("pages.home.hero.highlight")}
            </span>{" "}
            {t("pages.home.hero.titleSuffix")}
          </Title>
          <Text className={styles.subtitle}>
            {t("pages.home.hero.subtitle")}
          </Text>
          <Space size="large" className={styles.ctaButtons}>
            <Button
              type="primary"
              size="large"
              onClick={() => navigate("/trade")}
            >
              {t("pages.home.buttons.trade")}
            </Button>
            <Button size="large" onClick={() => navigate("/express")}>
              {t("pages.home.buttons.express")}
            </Button>
          </Space>
        </div>
      </div>

      {/* Features Section */}
      <div className={styles.features}>
        <Row gutter={[32, 32]} justify="center">
          <Col xs={24} sm={12} md={8}>
            <Card className={styles.featureCard}>
              <SafetyCertificateOutlined className={styles.icon} />
              <Title level={3}>{t("pages.home.features.security.title")}</Title>
              <Text>{t("pages.home.features.security.desc")}</Text>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Card className={styles.featureCard}>
              <ThunderboltOutlined className={styles.icon} />
              <Title level={3}>{t("pages.home.features.speed.title")}</Title>
              <Text>{t("pages.home.features.speed.desc")}</Text>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Card className={styles.featureCard}>
              <GlobalOutlined className={styles.icon} />
              <Title level={3}>{t("pages.home.features.freedom.title")}</Title>
              <Text>{t("pages.home.features.freedom.desc")}</Text>
            </Card>
          </Col>
        </Row>
      </div>

      {/* Stats Section */}
      <div className={styles.stats}>
        <Row gutter={[48, 48]} justify="center">
          <Col span={8}>
            <Title level={2}>{t("pages.home.stats.countries.number")}</Title>
            <Text>{t("pages.home.stats.countries.label")}</Text>
          </Col>
          <Col span={8}>
            <Title level={2}>{t("pages.home.stats.users.number")}</Title>
            <Text>{t("pages.home.stats.users.label")}</Text>
          </Col>
          <Col span={8}>
            <Title level={2}>{t("pages.home.stats.volume.number")}</Title>
            <Text>{t("pages.home.stats.volume.label")}</Text>
          </Col>
        </Row>
      </div>

      {/* CTA Section */}
      <div className={styles.cta}>
        <Card className={styles.ctaCard}>
          <RocketOutlined className={styles.ctaIcon} />
          <Title level={2}>{t("pages.home.cta.title")}</Title>
          <Text>{t("pages.home.cta.subtitle")}</Text>
          <Button
            className={styles.registerButton}
            type="primary"
            size="large"
            onClick={() => navigate("/register")}
          >
            {t("pages.home.cta.button")}
          </Button>
        </Card>
      </div>
    </div>
  );
};

export default Home;
