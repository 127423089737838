import React from "react";
import { Layout, Row, Col, Typography, Space, Divider } from "antd";
import {
  YoutubeOutlined,
  FacebookOutlined,
  TwitterOutlined,
} from "@ant-design/icons";
import styles from "./Footer.module.scss";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const { Text } = Typography;

const Footer: React.FC = () => {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();

  return (
    <Layout.Footer className={styles.footer}>
      <div className={styles.content}>
        <Row gutter={[32, 32]}>
          <Col xs={24} sm={12} md={6}>
            <div className={styles.section}>
              <Text strong className={styles.title}>
                {t("pages.footer.sections.about.title")}
              </Text>
              <Space direction="vertical">
                <Link to="/about">
                  {t("pages.footer.sections.about.company")}
                </Link>
                <Link to="/about">{t("pages.footer.sections.about.join")}</Link>
              </Space>
            </div>
          </Col>

          <Col xs={24} sm={12} md={6}>
            <div className={styles.section}>
              <Text strong className={styles.title}>
                {t("pages.footer.sections.products.title")}
              </Text>
              <Space direction="vertical">
                <Link to="/trade">
                  {t("pages.footer.sections.products.c2c")}
                </Link>
                <Link to="/express">
                  {t("pages.footer.sections.products.express")}
                </Link>
                <Link to="/apply">
                  {t("pages.footer.sections.products.merchant")}
                </Link>
              </Space>
            </div>
          </Col>

          <Col xs={24} sm={12} md={6}>
            <div className={styles.section}>
              <Text strong className={styles.title}>
                {t("pages.footer.sections.legal.title")}
              </Text>
              <Space direction="vertical">
                <Link to="/terms-of-service" target="_blank">
                  {t("pages.footer.sections.legal.terms")}
                </Link>
                <Link to="/privacy-policy" target="_blank">
                  {t("pages.footer.sections.legal.privacy")}
                </Link>
                {/* <Link>新手指南</Link>
                <Link>常见问题</Link>
                <Link>费率说明</Link>
                <Link>安全中心</Link> */}
              </Space>
            </div>
          </Col>

          <Col xs={24} sm={12} md={6}>
            <div className={styles.section}>
              <Text strong className={styles.title}>
                {t("pages.footer.sections.community.title")}
              </Text>
              <Space size="middle" className={styles.socialLinks}>
                <Link to="https://x.com/qjixn828961" target="_blank">
                  <TwitterOutlined />
                </Link>
                <Link
                  to="https://www.facebook.com/profile.php?id=61567911999921"
                  target="_blank"
                >
                  <FacebookOutlined />
                </Link>
                <Link to="https://www.youtube.com/@WhiteCoins" target="_blank">
                  <YoutubeOutlined />
                </Link>
              </Space>
            </div>
          </Col>
        </Row>

        <Divider className={styles.divider} />

        <div className={styles.bottom}>
          <Space split={<Divider type="vertical" />}>
            <Text
              type="secondary"
              style={{ color: "rgba(255, 255, 255, 0.45)" }}
            >
              © {currentYear} WhiteCoins. All rights reserved.
            </Text>
          </Space>
        </div>
      </div>
    </Layout.Footer>
  );
};

export default Footer;
