import React from "react";
import { Modal, Form, Radio, Input, Button } from "antd";
import styles from "./TradeModal.module.scss";
import { Space, Typography, Avatar, Divider } from "antd";
import { CheckCircleFilled } from "@ant-design/icons";

const { Title, Text } = Typography;

const TradeModal: React.FC = () => {
  function handleCancel() {}

  return (
    <Modal
      width={800}
      open={false}
      onCancel={handleCancel}
      centered
      footer={null}
      className={styles.container}
    >
      <div className={styles.content}>
        <div className={styles.sellerInfo}>
          <div className={styles.basicInfo}>
            <Avatar size={32} />
            <div className={styles.textBox}>
              <Text strong style={{ fontSize: 22, color: "white" }}>
                卖家姓名
              </Text>
              <Space>
                <CheckCircleFilled style={{ color: "#52c41a" }} />
                <Text style={{ color: "green" }}>保证金 10,000 USDT</Text>
              </Space>
            </div>
          </div>
          <div className={styles.entrustWrapper}>
            <div className={styles.entrust}>
              <Text style={{ color: "white" }}>成交单数: </Text>
              <Text style={{ color: "white" }}>1222</Text>
            </div>
            <div className={styles.entrust}>
              <Text style={{ color: "white" }}>成交率: </Text>
              <Text style={{ color: "white" }}>1222</Text>
            </div>
            <div className={styles.entrust}>
              <Text style={{ color: "white" }}>平均放行时间: </Text>
              <Text style={{ color: "white" }}>1222</Text>
            </div>
            <div className={styles.entrust}>
              <Text style={{ color: "white" }}>用户实名: </Text>
              <Text style={{ color: "white" }}>1222</Text>
            </div>
          </div>
          <Divider
            style={{
              backgroundColor: "white",
              marginTop: 16,
              marginBottom: 16,
            }}
          />
          <div className={styles.remarkWrapper}>
            <Text strong style={{ color: "white" }}>
              交易条件
            </Text>
            <Text style={{ color: "white" }}>尊敬的客户您好！</Text>
            <Text style={{ color: "white" }}>
              劳烦您用您发财的小手帮我点关注！
            </Text>
            <Text type="secondary">非常感谢您...更多</Text>
          </div>
        </div>
        <div className={styles.tradeForm}>
          <Form size="large" layout="vertical" style={{ width: "100%" }}>
            <Form.Item label="我要出售">
              <Input />
            </Form.Item>
            <Form.Item label="我将收到">
              <Input />
            </Form.Item>
            <Form.Item>
              <Button type="primary" block>
                出售
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

export default TradeModal;
