import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./user/userSlice";
import offerSlice from "./offer/offerSlice";
import accountSlice from "./account/accountSlice";
import paymentMethodSlice from "./payment-method/paymentMethodSlice";
import orderSlice from "./order/orderSlice";

const store = configureStore({
  reducer: {
    user: userSlice,
    offer: offerSlice,
    account: accountSlice,
    paymentMethod: paymentMethodSlice,
    order: orderSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
