import React from "react";
import { Link } from "react-router-dom";
import { Space, Typography } from "antd";
import styles from "./Logo.module.scss";
import { DollarTwoTone } from "@ant-design/icons";

const Logo: React.FC = () => {
  return (
    <div className={styles.container}>
      <Link to={"/"}>
        <Space>
          <Typography.Title>
            <DollarTwoTone />
          </Typography.Title>
          <Typography.Title>WhiteCoins</Typography.Title>
        </Space>
      </Link>
    </div>
  );
};

export default Logo;
