import React, { useEffect, useState } from "react";
import {
  Avatar,
  Dropdown,
  Layout,
  Menu,
  MenuProps,
  Button,
  Space,
} from "antd";
import Logo from "../logo/Logo";
import styles from "./Header.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { jwtDecode } from "jwt-decode";
import { logout } from "../../store/user/userSlice";
import LocalePicker from "../locale-picker/LocalePicker";
import { useTranslation } from "react-i18next";

export interface JwtPayload {
  userId: string;
  email: string;
  avatar: string;
}

const Header: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [avatar, setAvatar] = useState<string>("");
  const [userId, setUserId] = useState<string>("-1");

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { token } = useAppSelector((state) => state.user);

  const { t } = useTranslation();

  const menus = [
    { key: "/trade", label: t("pages.header.menu.c2c") },
    { key: "/express", label: t("pages.header.menu.express") },
    { key: "/orders", label: t("pages.header.menu.orders") },
    { key: "/rewards", label: t("pages.header.menu.rewards") },
    { key: "/referral", label: t("pages.header.menu.referral") },
    { key: "/apply", label: t("pages.header.menu.merchant") },
  ];

  const getDropdownItems = (
    handleLogout: () => void,
    navigate: (path: string) => void,
  ): MenuProps["items"] => [
    {
      key: "wallet",
      label: t("pages.header.userMenu.wallet"),
      onClick: () => navigate("/wallet"),
    },
    {
      key: "security",
      label: t("pages.header.userMenu.security"),
      onClick: () => navigate("/security"),
    },
    {
      key: "payments",
      label: t("pages.header.userMenu.payments"),
      onClick: () => navigate("/payment-method"),
    },
    {
      type: "divider",
    },
    {
      key: "logout",
      danger: true,
      label: t("pages.header.userMenu.logout"),
      onClick: handleLogout,
    },
  ];

  useEffect(() => {
    if (token) {
      const jwt = jwtDecode<JwtPayload>(token);
      setEmail(jwt.email);
      setAvatar(jwt.avatar);
      setUserId(jwt.userId);
      console.log(jwt.email, jwt.avatar, jwt.userId);
    }
  }, [token]);

  const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
  };

  const dropdownItems = getDropdownItems(handleLogout, navigate);

  const { pathname } = useLocation();
  const [current, setCurrent] = useState(menus[0].key);
  const onClick: MenuProps["onClick"] = (e) => {
    console.log("click ", e);
    setCurrent(e.key);
    navigate(e.key);
  };

  useEffect(() => {
    const currentPath = pathname.split("/")[1];
    const matchedMenu = menus.find((menu) =>
      menu.key.includes(`/${currentPath}`),
    );

    if (matchedMenu) {
      setCurrent(matchedMenu.key);
    } else {
      setCurrent("");
    }
  }, [pathname]);

  return (
    <Layout.Header className={styles.header}>
      <div className={styles.logo}>
        <Logo />
      </div>
      <Menu
        theme="dark"
        mode="horizontal"
        onClick={onClick}
        selectedKeys={[current]}
        items={menus}
        className={styles.nav}
      />
      <div className={styles.right}>
        {token ? (
          <Dropdown menu={{ items: dropdownItems }} arrow>
            <Space>
              <span className={styles.email}>{email}</span>
              <Avatar src={avatar} />
            </Space>
          </Dropdown>
        ) : (
          <Space>
            <Button onClick={() => navigate("/login")}>
              {t("pages.header.buttons.login")}
            </Button>
            <Button onClick={() => navigate("/register")}>
              {t("pages.header.buttons.register")}
            </Button>
          </Space>
        )}
        <div className={styles.localePicker}>
          <LocalePicker />
        </div>
      </div>
    </Layout.Header>
  );
};

export default Header;
