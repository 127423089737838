import React from "react";
import styles from "./PrivacyPolicy.module.scss";

const PrivacyPolicy: React.FC = () => {
  return (
    <div className={styles.container}>
      <main className="site-main">
        <article className="post tag-agreement no-image single">
          <header className="post-header container medium">
            <h1 className={styles.postTitle}>隐私政策</h1>
            <div className={styles.postMeta}>
              <div className={styles.postMetaContent}>
                <div className={styles.postDate}>
                  <time dateTime="2021-03-16">3月 16, 2021</time>
                </div>
                <div className={styles.postLength}>约 28 分钟读完</div>
              </div>
            </div>
          </header>
          <div
            className={`${styles.postContent} ${styles.kgCanvas} ${styles.uTextFormat}`}
          >
            <p>
              WhiteCoins.（以下称
              “公司”）是一家在新加坡注册成立的公司，该公司运营WhiteCoins平台（以下称“本平台”或“平台”），该平台是一个专门供用户进行数字资产交易和提供相关服务（以下称“该服务”或“服务”）的平台。为了表述之方便，公司和该平台在本协议中合称
              “我们”或其他第一人称称呼，只要登录该平台的自然人或其他主体均为本平台的用户，以下使用“您”或其他第二人称，我们和您合称为“双方”，我们或您单称为“一方”。
            </p>
            <p>
              我们充分理解与尊重您的个人信息对您的重要性，我们将按法律法规要求，采取相应安全保护措施，保护您的个人信息安全。鉴于此，制定本《隐私政策》（下称“本政策
              /本隐私政策”）并提醒您：
            </p>
            <p>
              本政策适用于我们提供的所有产品和服务。
              <strong>
                <strong>
                  如我们向您提供的产品或服务单独设立有隐私政策的，则该产品或服务适用其单独设立的隐私政策；如我们的产品或服务未单独设立隐私政策的，则适用于本政策。
                </strong>
              </strong>
            </p>
            <p>
              需要特别说明的是，
              <strong>
                <strong>
                  本政策不适用于其他第三方向您提供的服务，第三方向您提供的服务适用其向您另行说明的隐私政策。
                </strong>
              </strong>
            </p>
            <p>
              <strong>
                <strong>
                  在使用我们各项产品或服务前，请您务必仔细阅读并透彻理解本政策，特别是以粗体/粗体下划线标识的条款，您应重点阅读，在确认充分理解并同意后再开始使用。如对本政策内容有任何疑问、意见或建议，您可通过我们官方提供的各种联系方式与我们联系。如您不同意本政策中的任何条款，您应立即停止访问本平台。
                </strong>
              </strong>
            </p>
            <p>
              <strong>
                <strong>第一部分 定义</strong>
              </strong>
            </p>
            <p>
              <strong>
                <strong>个人信息：</strong>
              </strong>
              指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。
            </p>
            <p>
              <strong>
                <strong>个人敏感信息：指</strong>
              </strong>
              包括身份证件号码、个人生物识别信息、银行账号、财产信息、行踪轨迹、交易信息、14岁以下（含）儿童信息等的个人信息（我们将在本隐私权政策中对具体个人敏感信息以
              <strong>
                <strong>粗体</strong>
              </strong>
              进行显著标识）。
            </p>
            <p>
              <strong>
                <strong>个人信息删除：</strong>
              </strong>
              指在实现日常业务功能所涉及的系统中去除个人信息的行为，使其保持不可被检索、访问的状态。
            </p>
            <p>
              <strong>
                <strong>儿童</strong>
              </strong>
              ：指不满十四周岁的未成年人。
            </p>
            <p>
              <strong>
                <strong>第二部分 &nbsp;隐私政策</strong>
              </strong>
            </p>
            <p>本隐私政策部分将帮助您了解以下内容：</p>
            <p>一、我们如何收集和使用您的信息</p>
            <p>二、我们如何使用Cookie和同类技术</p>
            <p>三、我们如何共享、转让、公开披露您的信息</p>
            <p>四、我们如何保护您的信息</p>
            <p>五、您如何管理您的信息</p>
            <p>六、我们如何处理未成年人的信息</p>
            <p>七、本隐私政策如何更新</p>
            <p>八、如何联系我们</p>
            <p>
              <strong>
                <strong>一、 我们如何收集和使用您的信息</strong>
              </strong>
            </p>
            <p>
              在您使用我们的产品及/或服务时，我们需要/可能需要收集和使用的您的个人信息包括如下两种：
            </p>
            <p>
              1、
              <strong>
                <strong>
                  为实现向您提供我们产品及/或服务的基本功能，您须授权我们收集、使用的必要的信息。如您拒绝提供相应信息，您将无法正常使用我们的产品及/或服务；
                </strong>
              </strong>
            </p>
            <p>
              2、
              <strong>
                <strong>
                  为实现向您提供我们产品及/或服务的附加功能，您可选择授权我们收集、使用的信息。如您拒绝提供，您将无法正常使用相关附加功能或无法达到我们拟达到的功能效果，但并不会影响您正常使用我们产品及/或服务的基本功能。
                </strong>
              </strong>
            </p>
            <p>
              <strong>
                <u>
                  <strong>您理解并同意：</strong>
                </u>
              </strong>
            </p>
            <p>
              <strong>
                <strong>1、</strong>
                <u>
                  <strong>
                    我们致力于打造多样的产品和服务以满足您的需求。因我们向您提供的产品和服务种类众多，且不同用户选择使用的具体产品/服务范围存在差异，相应的，基本/附加功能及收集使用的个人信息类型、范围等会有所区别，请以具体的产品/服务功能为准
                  </strong>
                </u>
                <strong>；</strong>
              </strong>
            </p>
            <p>
              <strong>
                <strong>2、</strong>
                <u>
                  <strong>
                    为给您带来更好的产品和服务体验，我们在持续努力改进我们的技术，随之我们可能会不时推出新的或优化后的功能，可能需要收集、使用新的个人信息或变更个人信息使用目的或方式。对此，我们将通过更新本政策、弹窗、页面提示等方式另行向您说明对应信息的收集目的、范围及使用方式，并为您提供自主选择同意的方式，且在征得您明示同意后收集、使用
                  </strong>
                </u>
                <strong>。</strong>
              </strong>
              在此过程中，如果您有任何疑问、意见或建议的，您可通过本平台提供的各种联系方式与我们联系，我们会尽快为您作出解答。
            </p>
            <p>我们会为实现本政策下述的各项功能，收集和使用您的个人信息：</p>
            <p>
              <strong>
                <strong>
                  （一） 帮助您注册，并向您提供本平台所提供的服务；
                </strong>
              </strong>
            </p>
            <p>
              <strong>
                <strong>1、注册服务</strong>
              </strong>
            </p>
            <p>
              我们通过本平台账户为您提供基础会员服务。为注册成为会员并使用我们的会员服务，您在申请注册时需要填写和提供以下信息：
            </p>
            <p>
              （1）身份信息。该信息可以帮助我们验证您是否有资格注册为本平台会员，包括但不限于您的名字、您的居住地址、您所属国家或政府签发的其他具有证明您身份信息的不同的证书以及涉及到的号码及所有其他可帮助我们验证您身份的信息（以下合称为“身份信息”
              ）。该身份信息具体根据您是个人用户还是机构用户而有所不同，根据不同司法管辖区的法律和法规，我们针对来自不同国家或地区的用户所收集的信息可能有所不同。以下信息和数据作为一个指示性的示例：
            </p>
            <p>
              <strong>
                <strong>个人用户</strong>
              </strong>
              ：国籍、法定全名（包括曾用名和当地语言名称）、身份证号、电子邮件地址、电话号码、社会安全号码（“SSN”），护照号码或任何政府颁发的身份证号码、出生日期、身份证明（例如护照，驾驶执照或政府颁发的身份证明）。
            </p>
            <p>
              <strong>
                <strong>机构用户</strong>
              </strong>
              ：公司法定名称（包括当地语言的法定名称）、注册信息、商务电话号码、授权代表及授权用户个人信息、主要营业地点、所有实益拥有人的个人信息）、股东/实益拥有人的身份证明文件（护照或政府签发的身份证）、公司架构、公司成立相关文件、董事会决议、资金来源申报、风险与合规控制说明及平台要求的其他信息或者文件。
            </p>
            <p>
              （2）服务信息。该信息帮助我们与您联系并顺利为您提供不同模式的数字资产间交易服务，包括但不限于您的个人身份信息、联系信息、交易信息、以及您的借记卡信息和/或其他帐户信息（以下合称为“服务信息”
              ）。
              <strong>
                <strong>同时</strong>
              </strong>
              请您理解，我们向您提供的服务是不断更新和发展的。如您选择使用了前述说明当中尚未涵盖的其他服务，基于该服务我们需要收集您的
              <strong>
                <strong>额外</strong>
              </strong>
              信息的，我们会通过页面提示、交互流程、协议约定、弹窗提示等方式向您说明信息收集的范围与目的，并征得您的同意。
            </p>
            <p>
              如果您仅需使用浏览服务，您不需要注册成为我们的会员及提供上述信息。
            </p>
            <p>
              <strong>
                <strong>2、其他注册服务</strong>
              </strong>
            </p>
            <p>
              实名认证：当您注册登录后，需进入&#34;个人中心&#34;在身份认证中完成实名认证，您在使用实名认证过程中需向平台提供您的真实姓名、身份证号或护照号。
            </p>
            <p>
              高级认证：当您在本平台中的交易额超过一定限额时，平台为了维护您的资产安全，会要求对您进行高级认证。认证过程除实名认证信息外，您认可平台对您进行“人脸识别”的高级认证，人脸识别会涉及到平台对您的相关视频流或人脸图像的收集。
            </p>
            <p>
              <strong>
                <u>
                  <strong>
                    账户信息展示：如果您已拥有本平台账户，我们可能会在服务中显示您的上述个人信息（实名认证仅显示认证是否通过的结果），以及您在平台账户相关联的产品和服务中执行的操作，包括通过本平台账户集中展示您的个人资料、交易订单。我们会尊重您对平台账户设置所做的选择。
                  </strong>
                </u>
              </strong>
            </p>
            <p>
              <strong>
                <strong>（二）</strong>
              </strong>{" "}
              <strong>
                <strong>安全管理服务和服务信息推送服务</strong>
              </strong>
            </p>
            <p>
              当您使用本平台提供的服务或访问本平台时，本平台为保障您的交易安全及服务的安全稳定运行，我们需要自动接收并记录您的Web浏览器信息，您登录账户使用的设备类型、型号版本等设备识别符号等设备信息、您请求访问的网页上的IP地址及记录、所在地区、网络环境、设备指纹信息、服务日志信息、软件信息、位置信息等。
            </p>
            <p>
              <strong>
                <u>
                  <strong>
                    请注意，单独的设备信息是无法识别特定自然人身份的信息。
                  </strong>
                </u>
              </strong>
              如果我们将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人信息，除取得您授权或法律法规另有规定外，我们会将这类信息做匿名化、去标识化处理。
            </p>
            <p>
              <strong>
                <u>
                  <strong>
                    为向您提供更便捷、更符合您个性化需求的信息展示及推送服务，我们未来可能会根据您的设备信息和您本平台或客户端的浏览使用信息，提取您的偏好特征，并基于特征标签产出间接人群画像，用于展示、推送信息和可能的商业广告。若您不同意接收此类推送信息或撤回同意收集个人信息，您可根据本政策提供的联系方式与我们联系。
                  </strong>
                </u>
              </strong>
            </p>
            <p>
              我们努力保障您的浏览体验。如果您不想接受我们给您发送的商业广告，您可通过短信提示回复退订或我们提供的其他方式进行退订或关闭。
            </p>
            <p>
              此外，我们也会为了不断改进和优化上述的功能来使用您的上述信息。
            </p>
            <p>
              <strong>
                <strong>（三）交易记录保管及查询服务</strong>
              </strong>
            </p>
            <p>
              我们就您在本平台上的交易所收集的相关数据，包括但不限于交易记录。如您需查询您的订单交易记录，可在平台“订单管理”中查询您的个人交易明细，具体包括：交易对方昵称、下单时间、订单号、交易信息、数字资产种类及数量等。
            </p>
            <p>
              <strong>
                <strong>（四） 客服及争议处理</strong>
              </strong>
            </p>
            <p>
              当您与我们联系或提出售中售后、争议纠纷处理申请时，为了保障您的账户及系统安全，
              <strong>
                <u>
                  <strong>
                    我们需要您提供必要的个人信息以核验您的会员身份。
                  </strong>
                </u>
              </strong>
            </p>
            <p>
              <strong>
                <u>
                  <strong>
                    为便于与您联系、尽快帮助您解决问题或记录相关问题的处理方案及结果，我们可能会保存您与我们的通信记录及相关内容（包括账号信息、订单信息、您为了证明相关事实提供的其他信息，或您留下的联系方式信息），如果您针对具体订单进行咨询、投诉或提供建议的，我们会使用您的账号信息和订单信息。
                  </strong>
                </u>
              </strong>
            </p>
            <p>
              为了提供服务及改进服务质量的合理需要，我们还可能使用的您的其他信息，包括您与客服联系时您提供的相关信息，您参与问卷调查时向我们发送的问卷答复信息。
            </p>
            <p>
              <strong>
                <strong>（五）其他</strong>
              </strong>
            </p>
            <p>
              <strong>
                <u>
                  <strong>
                    1、若你提供的信息中含有其他用户的个人信息，在向本平台提供这些个人信息之前，您需确保您已经取得合法的授权。若其中涉及儿童个人信息的，您需在发布前取得对应儿童监护人的同意，前述情形下监护人有权通过本政策第九条的途径联系我们，要求更正或删除涉及儿童个人信息的内容。
                  </strong>
                </u>
              </strong>
            </p>
            <p>
              <strong>
                <u>
                  <strong>
                    2、若我们将信息用于本政策未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的，或者我们主动从第三方处获取您的个人信息，均会事先获得您的同意。
                  </strong>
                </u>
              </strong>
            </p>
            <p>
              若我们从第三方处间接获取您的信息的，我们会在收集前明确以书面形式要求该第三方在已依法取得您同意后收集个人信息，并向您告知共享的信息内容，且涉及敏感信息的在提供给我们使用前需经过您的明确确认，要求第三方对个人信息来源的合法性和合规性作出承诺，如第三方有违反行为的，我们会明确要求对方承担相应法律责任；同时，我们对个人信息会进行安全加固（包括敏感信息报备、敏感信息加密存储、访问权限控制等）。我们会使用不低于我们对自身用户个人信息同等的保护手段与措施对间接获取的个人信息进行保护。
            </p>
            <p>
              <strong>
                <strong>3、征得授权同意的例外</strong>
              </strong>
            </p>
            <p>
              您充分理解并同意，我们在以下情况下收集、使用您的个人信息无需您的授权同意，且我们可能不会响应您提出的更正/修改、删除、注销、撤回同意、索取信息的请求：
            </p>
            <p>（1）与国家安全、国防安全有关的；</p>
            <p>（2）与公共安全、公共卫生、重大公共利益有关的；</p>
            <p>（3）与犯罪侦查、起诉、审判和判决执行等司法或行政执法有关的；</p>
            <p>
              （4）出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
            </p>
            <p>（5）您自行向社会公众公开的个人信息；</p>
            <p>
              （6）从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。
            </p>
            <p>（7）根据与您签订和履行相关协议或其他书面文件所必需的；</p>
            <p>
              （8）用于维护所提供的产品及/或服务的安全稳定运行所必需的，例如发现、处置产品及/或服务的故障；
            </p>
            <p>（9）为合法的新闻报道所必需的；</p>
            <p>
              （10）学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；
            </p>
            <p>（11）法律法规规定的其他情形。</p>
            <p>
              请知悉，根据适用的法律，若我们
              <strong>
                <u>
                  <strong>
                    对个人信息采取技术措施和其他必要措施进行处理，使得数据接收方无法重新识别特定个人且不能复原
                  </strong>
                </u>
              </strong>
              ，或我们可能会对收集的信息进行去标识化地研究、统计分析和预测，用于改善本平台的内容和布局，为商业决策提供产品或服务支撑，以及改进我们的产品和服务（包括使用匿名数据进行机器学习或模型算法训练），
              <strong>
                <u>
                  <strong>
                    则此类处理后数据的使用无需另行向您通知并征得您的同意
                  </strong>
                </u>
              </strong>
              。
            </p>
            <p>
              4、如我们停止运营本平台产品或服务，我们将及时停止继续收集您个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知您，并对我们所持有的与已关停业务相关的个人信息进行删除或匿名化处理。
            </p>
            <p>
              <strong>
                <strong>二、我们如何使用Cookie</strong>
              </strong>
            </p>
            <p>
              <strong>
                <u>
                  <strong>
                    如果您的浏览器或浏览器附加服务允许，您可修改对Cookie的接受程度或拒绝我们的Cookie。
                  </strong>
                </u>
              </strong>
              有关详情，请参见
              AboutCookies.org。但如果您这么做，在某些情况下可能会影响您安全访问我们的平台，且可能需要在每一次访问我们的平台时更改用户设置。您理解并且同意：
            </p>
            <p>
              1、 当您到访我们平台时，我们通过cookies使用Google
              Stats去记录我们的业绩以及核对在线广告的效果。Cookie是发送到您的浏览器上并在您的电脑硬盘驱动器上存储的小量数据。只有当您使用您的电脑进入我们平台时,Cookie才能够被发送到您的电脑硬盘上。
            </p>
            <p>
              2、Cookies常用于记录访问者浏览我们平台上的各个项目时的习惯以及偏好。Cookies所搜集的资料是不记名的集体统计数据，不载有个人资料。
            </p>
            <p>
              3、Cookies不能用于取得您的硬盘上的数据、您的电邮地址、及您的私人数据，其可使本平台或服务商系统识别到您的浏览器并捕捉和记忆信息。大多数浏览器都预设为可以接受Cookies。您可以选择将您的浏览器设定为不接受Cookies,或如果Cookies一被装上就通知您。不过，若设定为禁止Cookies，您或许便不能启动或使用我们平台的某些功能。
            </p>
            <p>
              <strong>
                <strong>三、我们如何共享、转让、公开披露您的信息</strong>
              </strong>
            </p>
            <p>
              <strong>
                <strong>（一）共享</strong>
              </strong>
            </p>
            <p>
              我们不会与本平台服务提供者以外的公司、组织和个人共享您的个人信息，但以下情况除外：
            </p>
            <p>
              1、在法定情形下的共享：我们可能会根据法律法规规定、诉讼、争议解决需要，或按行政、司法机关依法提出的要求，对外共享您的个人信息。
            </p>
            <p>
              2、在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息。
            </p>
            <p>
              3、在您主动选择情况下共享：我们会根据您的选择，将您的订单信息中与交易有关的必要信息共享给数字资产的交易对方，以实现您的交易及售后服务需求。
            </p>
            <p>
              4、与授权合作伙伴共享：我们的关联方、帮助我们经营我们的平台、开展业务、或者向您提供服务的受信任的第三方，只要这些当事方同意将这些信息保密；当我们相信披露的信息是适当的，是遵守法律、法规、规章制度的或来自于法院或他主管当局的命令、执行我们的平台策略，正常运行平台所需要，关联方提供服务所需或保护我们或他人的权利、财产或安全的。
            </p>
            <p>
              <strong>
                <strong>（二）转让</strong>
              </strong>
            </p>
            <p>
              我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：
            </p>
            <p>
              1、在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；
            </p>
            <p>
              2、在本平台发生合并、收购或破产清算情形，或其他涉及合并、收购或破产清算情形时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本政策的约束，否则我们将要求该公司、组织和个人重新向您征求授权同意。
            </p>
            <p>
              <strong>
                <strong>（三）公开披露</strong>
              </strong>
            </p>
            <p>我们仅会在以下情况下，公开披露您的个人信息：</p>
            <p>
              1、获得您明确同意或基于您的主动选择，我们可能会公开披露您的个人信息；
            </p>
            <p>
              2、
              <strong>
                <u>
                  <strong>
                    如果我们确定您出现违反法律法规或严重违反本平台相关协议及规则的情况，或为保护本平台用户或公众的人身财产安全免遭侵害，我们可能依据法律法规或征得您同意的情况下披露关于您的个人信息
                  </strong>
                </u>
              </strong>
              ，包括相关违规行为以及本平台已对您采取的措施。
            </p>
            <p>
              <strong>
                <strong>
                  （四）共享、转让、公开披露个人信息时事先征得授权同意的例外
                </strong>
              </strong>
            </p>
            <p>
              以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：
            </p>
            <p>1、与国家安全、国防安全有关的；</p>
            <p>2、与公共安全、公共卫生、重大公共利益有关的；</p>
            <p>3、与犯罪侦查、起诉、审判和判决执行等司法或行政执法有关的；</p>
            <p>
              4、出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
            </p>
            <p>5、您自行向社会公众公开的个人信息；</p>
            <p>
              6、从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。
            </p>
            <p>
              请知悉，根据适用的法律，若我们
              <strong>
                <u>
                  <strong>
                    对个人信息采取技术措施和其他必要措施进行处理，使得数据接收方无法重新识别特定个人且不能复原，则此类处理后数据的共享、转让、公开披露无需另行向您通知并征得您的同意
                  </strong>
                </u>
              </strong>
              。
            </p>
            <p>
              <strong>
                <strong>四、我们如何保护您的信息</strong>
              </strong>
            </p>
            <p>
              1、我们实施妥善的实物、电子、管理及技术方面的措施来保护和保障您的个人资料的安全。我们尽力确保通过我们平台所搜集的任何个人资料皆免于任何与我们无关的第三者的滋扰。我们采取的安全措施包括但不限于：
            </p>
            <p>（1）实物措施：存有您个人资料的记录会被存放在有锁的地方。</p>
            <p>
              （2）电子措施：存有您个人资料的电脑数据会被存放在受严格登入限制的电脑系统和存储媒体上。
            </p>
            <p>
              （3）管理措施：我们内部设立有对用户信息进行安全保护的相关部门，并建立了相关内控制度，对可能接触到您的信息的工作人员采取严格授权原则，只有经我们授权的职员才能接触到您的个人资料，这些职员需要遵守我们个人资料保密的内部守则。此外，我们不断对工作人员培训相关法律法规及隐私安全准则和安全意识强化宣导，并定期组织内部相关人员进行应急响应培训和应急演练，使其掌握岗位职责和应急处置策略和规程
            </p>
            <p>
              （4）技术措施：可能采用如Secure Socket Layer
              Encryption这种加密技术来输送您的个人资料。
            </p>
            <p>
              （5）安全措施：为保障您的信息安全，我们致力于使用各种现行通用的安全技术及配套的管理体系来尽量降低您的信息被泄露、毁损、误用、非授权访问、非授权披露和更改的风险。例如：通过网络安全层软件（SSL）进行加密传输、信息加密存储、严格限制数据中心的访问。传输和存储个人敏感信息（含个人生物识别信息）时，我们将采用加密、权限控制、去标识化、脱敏化等安全措施。
            </p>
            <p>
              （6）其它措施：定期审查我们的个人资料收集，存储和处理流程;
              并根据必要的原则限制我们的员工和供应商对您数据的访问权限，且我们的员工和供应商均需遵守严格的合同保密义务。。
            </p>
            <p>
              2、若您知悉我们的平台上有任何安全方面的漏洞，请马上联系我们，使我们可以尽快采取妥适的行动。
            </p>
            <p>
              3、尽管实施了上述技术和保安的措施，我们不能保证资料在互联网上的输送绝对安全，因此我们不能绝对保证您通过我们平台提供给我们的个人资料在一切时候都是安全的。我们将不定期更新并公开安全风险、个人信息安全影响评估报告等有关内容，您可通过本平台公告方式获得。
            </p>
            <p>
              <strong>
                <strong>五、您如何管理您的信息</strong>
              </strong>
            </p>
            <p>您可以通过以下方式访问及管理您的信息：</p>
            <p>
              <strong>
                <strong>（一）查询、更正和删除您的信息</strong>
              </strong>
            </p>
            <p>
              您有权根据要求获得您的个人资料副本，并确定我们掌握的关于您的信息是否准确并且是最新的。如果您的任何个人数据不准确，您可以要求更新您的信息。您也可以要求删除您的个人资料，但我们可能会在某些情况下拒绝您的删除请求，例如出于法律要求或其他法律目的。有关数据访问，更正或删除请求，您可以通过第八条中的联系方式联系我们。
            </p>
            <p>
              为了响应数据访问，更正或删除请求，我们将验证请求方的身份，以确保他/她在法律上有权提出此类请求。虽然我们的目标是免费回复这些请求，但我们保留在您的请求重复或繁重时向您收取合理费用的权利。
            </p>
            <p>
              <strong>
                <strong>（二）改变您授权同意的范围</strong>
              </strong>
            </p>
            <p>
              每个业务功能需要一些基本的个人信息才能得以完成（见本隐私权政策“第一部分”）。除此之外，您可以通过第八条中的联系方式联系我们给予或收回您的授权同意。
            </p>
            <p>
              当您收回同意后，我们将不再处理相应的个人信息。
              <strong>
                <u>
                  <strong>
                    但您收回同意的决定，不会影响此前基于您的授权而开展的个人信息处理。
                  </strong>
                </u>
              </strong>
            </p>
            <p>
              <strong>
                <strong>（三）</strong>永久禁用您的账户
              </strong>
            </p>
            <p>
              在您主动申请禁用账户之后，我们将停止为您提供该产品的相关服务，根据适用法律的要求删除您的个人信息，或使其匿名化处理；您可以通过第八条中的联系方式联系我们来申请永久禁用您的账户。
            </p>
            <p>
              <strong>
                <strong>（四）响应您的上述请求</strong>
              </strong>
            </p>
            <p>
              为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。
            </p>
            <p>
              我们将在15天内做出答复。如您不满意，还可以通过我们提供的联系方式发起投诉。
            </p>
            <p>
              对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将酌情收取一定费用。对于与您的身份不直接关联的信息、无端重复信息，或者需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者不切实际的请求，我们可能会予以拒绝。
            </p>
            <p>在以下情形中，按照法律法规要求，我们将无法响应您的请求：</p>
            <p>1、与国家安全、国防安全有关的；</p>
            <p>2、与公共安全、公共卫生、重大公共利益有关的；</p>
            <p>3、与犯罪侦查、起诉、审判和执行判决等有关的；</p>
            <p>4、有充分证据表明个人信息主体存在主观恶意或滥用权利的；</p>
            <p>
              5、响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；
            </p>
            <p>6、涉及商业秘密的。</p>
            <p>
              <strong>
                <strong>六、我们如何处理未成年人的信息</strong>
              </strong>
            </p>
            <p>
              本平台向儿童开放，如果我们发现或怀疑用户未满14周岁，我们会要求用户关闭其帐户，且不会允许该用户继续使用平台产品或服务。我们会尽快删除有关资料。如果您知道有任何14周岁以下的人使用我们的服务，请通知我们，以便我们采取行动阻止他们获取我们的产品或服务。
            </p>
            <p>
              对于已满14岁但尚未满18周岁的未成年人，我们期望父母或监护人指导未成年人使用我们的服务。我们将根据相关法律法规的规定保护未成年人的信息的保密性及安全性。
            </p>
            <p>
              如您为未成年人，建议请您的父母或监护人阅读本政策，并在征得您父母或监护人同意的前提下使用我们的服务或向我们提供您的信息。对于经父母或监护人同意而收集您的信息的情况，我们只会在受到法律允许、父母或监护人明确同意或者保护您的权益所必要的情况下使用或公开披露此信息。如您的监护人不同意您按照本政策使用我们的服务或向我们提供信息，请您立即终止使用我们的服务并及时通知我们，以便我们采取相应的措施。
            </p>
            <p>
              如您为未成年人的父母或监护人，当您对您所监护的未成年人的信息处理存在疑问时，请通过本政策公布的联系方式联系我们。
            </p>
            <p>
              <strong>
                <strong>七、本隐私权政策如何更新</strong>
              </strong>
            </p>
            <p>我们的隐私权政策可能变更。</p>
            <p>
              未经您明确同意，我们不会限制您按照本隐私政策所应享有的权利。我们会在官方渠道上发布对隐私政策所做的任何变更。如果您不同意修订的内容，您应停止立即访问本平台。当隐私政策的更新版本发布，您持续访问本平台显示和表明您同意该更新的内容，并同意遵守该更新的隐私政策。
            </p>
            <p>
              对于重大变更，我们还会提供更为显著的通知（包括我们会通过本平台公示的方式进行通知甚至向您提供弹窗提示）。
            </p>
            <p>本政策所指的重大变更包括但不限于：</p>
            <p>
              1、我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；
            </p>
            <p>
              2、我们在控制权等方面发生重大变化。如并购重组等引起的信息控制者变更等；
            </p>
            <p>3、个人信息共享、转让或公开披露的主要对象发生变化；</p>
            <p>4、您参与个人信息处理方面的权利及其行使方式发生重大变化；</p>
            <p>
              5、我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化；
            </p>
            <p>6、个人信息安全影响评估报告表明存在高风险。</p>
            <p>
              <strong>
                <strong>八、如何联系我们</strong>
              </strong>
            </p>
            <p>
              1、如果您有任何要求和意见，您可以通过电子邮件support@whitecoins.top，这是属于我们与您沟通的唯一有效和官方电子邮件，所以我们对您未使用有效的联系方式，任何作为或不作为不承担责任。
            </p>
            <p>
              2、我们只通过本平台上的有效的联系方式发布公告和信息或在本平台张贴公告，所以我们对由于您信任了未通过以上方式获得的信息而产生的任何损失不承担责任。
            </p>
            <p>3、若您对我们的隐私政策有任何问题，欢迎随时联系我们。</p>
          </div>
        </article>
      </main>
    </div>
  );
};

export default PrivacyPolicy;
