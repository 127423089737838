import React from "react";
import styles from "./OrderList.module.scss";
import Paging from "../../components/paging/Paging";
import { Divider, Select, Space, Typography, Spin } from "antd";
import OrderCard from "../../components/order-card/OrderCard";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { getOrderList } from "../../store/order/orderSlice";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { LIST_PAGE_PARAM_KEY, LIST_PAGE_SIZE_PARAM_KEY } from "../../constants";
import { useTranslation } from "react-i18next";

const OrderList: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { records: orders, total, loading } = useAppSelector((state) => state.order);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const page = parseInt(searchParams.get(LIST_PAGE_PARAM_KEY) || "1");
    const size = parseInt(searchParams.get(LIST_PAGE_SIZE_PARAM_KEY) || "20");
    dispatch(getOrderList({ page, size, order_status: 0 }));
  }, [dispatch, searchParams]);

  if (loading) {
    return (
      <div style={{ textAlign: "center", padding: "50px" }}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Space wrap>
          <Select
            defaultValue={t("pages.orders.filters.status.all")}
            style={{ width: 120 }}
            options={[
              { value: "all", label: t("pages.orders.filters.status.all") },
              { value: "complete", label: t("pages.orders.filters.status.complete") },
              { value: "cancel", label: t("pages.orders.filters.status.cancel") }
            ]}
          />
          <Select
            defaultValue={t("pages.orders.filters.type.all")}
            style={{ width: 120 }}
            options={[
              { value: "all", label: t("pages.orders.filters.type.all") },
              { value: "buy", label: t("pages.orders.filters.type.buy") },
              { value: "sell", label: t("pages.orders.filters.type.sell") }
            ]}
          />
          <Select
            defaultValue="USDT"
            style={{ width: 120 }}
            options={[
              { value: "ALL", label: t("pages.orders.filters.currency.all") },
              { value: "USDT", label: t("pages.orders.filters.currency.USDT") },
              { value: "ETH", label: t("pages.orders.filters.currency.ETH"), disabled: true },
              { value: "BTC", label: t("pages.orders.filters.currency.BTC"), disabled: true },
              { value: "USDC", label: t("pages.orders.filters.currency.USDC"), disabled: true }
            ]}
          />
        </Space>
      </div>
      <div className={styles.content}>
        <div className={styles.title}>
          <Typography.Text type="secondary" className={styles.number}>
            {t("pages.orders.table.columns.orderNo")}
          </Typography.Text>
          <Typography.Text type="secondary" className={styles.info}>
            {t("pages.orders.table.columns.direction")}
          </Typography.Text>
          <Typography.Text type="secondary" className={styles.info}>
            {t("pages.orders.table.columns.amount")}
          </Typography.Text>
          <Typography.Text type="secondary" className={styles.info}>
            {t("pages.orders.table.columns.total")}
          </Typography.Text>
          <Typography.Text type="secondary" className={styles.info}>
            {t("pages.orders.table.columns.price")}
          </Typography.Text>
          <Typography.Text type="secondary" className={styles.info}>
            {t("pages.orders.table.columns.counterparty")}
          </Typography.Text>
          <Typography.Text type="secondary" className={styles.info}>
            {t("pages.orders.table.columns.status")}
          </Typography.Text>
          <Typography.Text type="secondary" className={styles.info}>
            {t("pages.orders.table.columns.time")}
          </Typography.Text>
        </div>
        <Divider style={{ marginTop: 8 }} />
        {orders.map((order) => (
          <OrderCard key={order.id} {...order} />
        ))}
      </div>
      <div className={styles.footer}>
        <Paging total={total} />
      </div>
    </div>
  );
};

export default OrderList;
