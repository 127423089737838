import React, { FC } from "react";
import { useAppSelector } from "../store/hooks";
import { LOGIN_PATHNAME } from "./index";
import { Navigate } from "react-router-dom";

type Props = {
  children: React.ReactNode;
};

const AuthGuard: FC<Props> = ({ children }) => {
  const { token } = useAppSelector((state) => state.user);
  if (token) {
    return <>{children}</>;
  }
  return <Navigate to={LOGIN_PATHNAME} />;
};

export default AuthGuard;
