import React from "react";
import { Card, Typography, Input, Space, Button, Select } from "antd";
import {
  DollarCircleOutlined,
  ClockCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import styles from "./Express.module.scss";
import { useTranslation } from "react-i18next";

const { Title, Text } = Typography;

const Express: React.FC = () => {
  const { t } = useTranslation();
  const selectedFiat = "USD";
  const selectedCrypto = "BTC";

  const cryptoSelector = (
    <Select defaultValue="USDT">
      {Object.keys(t("pages.express.currency.crypto", { returnObjects: true })).map(key => (
        <Select.Option key={key} value={key} disabled={key !== "USDT"}>
          {t(`pages.express.currency.crypto.${key}`)}
        </Select.Option>
      ))}
    </Select>
  );

  const fiatSelector = (
    <Select defaultValue="USD">
      {Object.keys(t("pages.express.currency.fiat", { returnObjects: true })).map(key => (
        <Select.Option key={key} value={key} disabled={key === "CNY"}>
          {t(`pages.express.currency.fiat.${key}`)}
        </Select.Option>
      ))}
    </Select>
  );

  return (
    <div className={styles.container}>
      <Card className={styles.mainCard}>
        <div className={styles.header}>
          <Title level={2}>{t("pages.express.title")}</Title>
          <Title level={3}>
            {t("pages.express.subtitle", { fiat: selectedFiat, crypto: selectedCrypto })}
          </Title>
          <Text type="secondary">
            {t("pages.express.description", { crypto: selectedCrypto })}
          </Text>
        </div>

        <div className={styles.tradeForm}>
          <Space direction="vertical" size="large" style={{ width: "100%" }}>
            <div className={styles.inputGroup}>
              <Title level={4}>{t("pages.express.form.pay.title")}</Title>
              <Input
                size="large"
                addonAfter={fiatSelector}
                placeholder={t("pages.express.form.pay.placeholder")}
              />
            </div>

            <div className={styles.inputGroup}>
              <Title level={4}>{t("pages.express.form.receive.title")}</Title>
              <Input size="large" addonAfter={cryptoSelector} />
            </div>

            <Button type="primary" size="large" block disabled>
              {t("pages.express.form.submit")}
            </Button>
          </Space>
        </div>
      </Card>

      <Card className={styles.stepsCard}>
        <Title level={3}>
          {t("pages.express.guide.title", { fiat: selectedFiat, crypto: selectedCrypto })}
        </Title>

        <div className={styles.steps}>
          <div className={styles.step}>
            <div className={styles.icon}>
              <DollarCircleOutlined />
            </div>
            <Title level={4}>
              {t("pages.express.guide.steps.match.title", { crypto: selectedCrypto })}
            </Title>
            <Text type="secondary">
              {t("pages.express.guide.steps.match.desc", { 
                crypto: selectedCrypto,
                fiat: selectedFiat 
              })}
            </Text>
          </div>

          <div className={styles.step}>
            <div className={styles.icon}>
              <ClockCircleOutlined />
            </div>
            <Title level={4}>
              {t("pages.express.guide.steps.pay.title", { fiat: selectedFiat })}
            </Title>
            <Text type="secondary">
              {t("pages.express.guide.steps.pay.desc", { crypto: selectedCrypto })}
            </Text>
          </div>

          <div className={styles.step}>
            <div className={styles.icon}>
              <CheckCircleOutlined />
            </div>
            <Title level={4}>
              {t("pages.express.guide.steps.receive.title", { crypto: selectedCrypto })}
            </Title>
            <Text type="secondary">
              {t("pages.express.guide.steps.receive.desc", { crypto: selectedCrypto })}
            </Text>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default Express;
