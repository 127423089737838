import React, { useEffect } from "react";
import styles from "./SellList.module.scss";
import { Divider, Select, Space, Spin, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { useSearchParams } from "react-router-dom";
import { LIST_PAGE_PARAM_KEY, LIST_PAGE_SIZE_PARAM_KEY } from "../../constants";
import { getOfferList } from "../../store/offer/offerSlice";
import SellerCard from "../../components/seller-card/SellerCard";
import Paging from "../../components/paging/Paging";
import TradeModal from "../../components/trade-modal/TradeModal";

const SellList: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { records: sellers, loading, total } = useAppSelector((state) => state.offer);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const page = parseInt(searchParams.get(LIST_PAGE_PARAM_KEY) || "1");
    const size = parseInt(searchParams.get(LIST_PAGE_SIZE_PARAM_KEY) || "20");
    dispatch(getOfferList({ page, size, side: 2 }));
  }, [dispatch, searchParams]);

  if (loading) {
    return (
      <div style={{ textAlign: "center", padding: "50px" }}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Space wrap>
          <Select
            defaultValue="USDT"
            style={{ width: 120 }}
            options={[
              { value: "USDT", label: t("pages.trade.filters.currency.USDT") },
              { value: "ETH", label: t("pages.trade.filters.currency.ETH"), disabled: true },
              { value: "BTC", label: t("pages.trade.filters.currency.BTC"), disabled: true },
              { value: "USDC", label: t("pages.trade.filters.currency.USDC"), disabled: true },
            ]}
          />
          <Select
            defaultValue="wechat"
            style={{ width: 150 }}
            options={[
              { value: "all", label: t("pages.trade.filters.payment.all") },
              { value: "wechat", label: t("pages.trade.filters.payment.wechat") },
              { value: "alipay", label: t("pages.trade.filters.payment.alipay") },
              { value: "card", label: t("pages.trade.filters.payment.card") },
            ]}
          />
        </Space>
      </div>
      <div className={styles.content}>
        <div className={styles.title}>
          <Typography.Text type="secondary" className={styles.mch}>
            {t("pages.trade.table.columns.merchant")}
          </Typography.Text>
          <Typography.Text type="secondary" className={styles.info}>
            {t("pages.trade.table.columns.price")}
          </Typography.Text>
          <Typography.Text type="secondary" className={styles.info}>
            {t("pages.trade.table.columns.amount")}
          </Typography.Text>
          <Typography.Text type="secondary" className={styles.info}>
            {t("pages.trade.table.columns.payment")}
          </Typography.Text>
          <Typography.Text type="secondary" className={styles.info}>
            {t("pages.trade.table.columns.action")}
          </Typography.Text>
        </div>
        <Divider style={{ marginTop: 8 }} />
        {sellers.map((seller) => (
          <SellerCard key={seller.id} {...seller} />
        ))}
      </div>
      <div className={styles.footer}>
        <Paging total={total} />
      </div>
      <TradeModal />
    </div>
  );
};

export default SellList;
