import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Paging } from "../../types/api";
import { OfferInfo } from "../../types/entity";
import api from "../../services/api";

interface OfferState {
  loading: boolean;
  error: string | null;
  records: OfferInfo[];
  total: number;
  size: number;
  current: number;
}

const initialState: OfferState = {
  loading: false,
  error: null,
  records: [],
  total: 0,
  size: 20,
  current: 1,
};

export const getOfferList = createAsyncThunk(
  "offer/getOfferList",
  async (
    params: { page: number; size: number; side: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.getOfferList(
        params.page,
        params.size,
        params.side
      );
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || "获取列表失败");
    }
  }
);

export const offerSlice = createSlice({
  name: "offer",
  initialState,
  reducers: {
    clearOffers: (state) => {
      state.records = [];
      state.total = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOfferList.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getOfferList.fulfilled, (state, action) => {
      const { records, total, size, current } = action.payload;
      state.loading = false;
      state.records = records;
      state.total = total;
      state.size = size;
      state.current = current;
    });
    builder.addCase(getOfferList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
  },
});

export const { clearOffers } = offerSlice.actions;
export default offerSlice.reducer;
