import React, { FC, useEffect } from "react";
import styles from "./Trade.module.scss";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Card, Segmented } from "antd";
import { useTranslation } from "react-i18next";

const Trade: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [tradeType, setTradeType] = React.useState<string | number>("sell");

  useEffect(() => {
    if (pathname.startsWith("/trade/sell")) {
      setTradeType("sell");
    } else if (pathname.startsWith("/trade/buy")) {
      setTradeType("buy");
    } else {
      navigate("/trade/sell");
    }
  }, [pathname]);

  function handleSegmentChange(value: string | number) {
    setTradeType(value);
    if (value === "sell") {
      navigate("/trade/sell");
    } else {
      navigate("/trade/buy");
    }
  }

  return (
    <Card className={styles.container}>
      <div className={styles.tabs}>
        <Segmented
          size="large"
          style={{ marginBottom: 8 }}
          value={tradeType}
          onChange={(value) => handleSegmentChange(value)}
          options={[
            { label: t("pages.trade.tabs.sell"), value: "sell" },
            { label: t("pages.trade.tabs.buy"), value: "buy", disabled: true },
          ]}
        />
      </div>
      <Outlet />
    </Card>
  );
};

export default Trade;
