import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "../../services/api";
import storage from "../../util/storage";
import { InvitedUser, InviteCode } from "../../types/entity";
const LOGIN_COUNTDOWN_KEY = "login_countdown_end_time";
const REGISTER_COUNTDOWN_KEY = "register_countdown_end_time";

export const TOKEN_KEY = "user_token";

interface LoginState {
  loading: boolean;
  error: string | null;
  token: string | null;
  loginCountdown: number;
  registerCountdown: number;
  inviteCode: InviteCode | null;
  invitedUsers: InvitedUser[];
}

const initialState: LoginState = {
  loading: false,
  error: null,
  token: storage.get(TOKEN_KEY) || null,
  loginCountdown: 0,
  registerCountdown: 0,
  inviteCode: null,
  invitedUsers: [],
};

/**
 * 登录
 * @param params
 * @param rejectWithValue
 * @returns
 */
export const login = createAsyncThunk(
  "user/login",
  async (
    params: { email: string; password: string; code: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.login(params.email, params.password, params.code);
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || "登录失败");
    }
  },
);

/**
 * 注册
 * @param params
 * @param rejectWithValue
 * @returns
 */
export const register = createAsyncThunk(
  "user/register",
  async (
    params: {
      email: string;
      password: string;
      password2: string;
      code: string;
      invite_code?: string;
    },
    { rejectWithValue },
  ) => {
    try {
      return await api.register(
        params.email,
        params.password,
        params.password2,
        params.code,
        params.invite_code,
      );
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || "注册失败");
    }
  },
);

/**
 * 发送验证码
 * @param email
 * @param rejectWithValue
 * @returns
 */
export const sendCode = createAsyncThunk(
  "user/sendCode",
  async (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { email, isLogin }: { email: string; isLogin: boolean },
    { rejectWithValue },
  ) => {
    try {
      await api.code(email);
      return true;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

/**
 * 获取邀请码
 */
export const getInviteCode = createAsyncThunk(
  "user/getInviteCode",
  async (_, { rejectWithValue }) => {
    try {
      return await api.getInviteCode();
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

/**
 * 获取已邀请用户
 */
export const getInvitedUsers = createAsyncThunk(
  "user/getInvitedUsers",
  async (_, { rejectWithValue }) => {
    try {
      return await api.getInvitedUsers();
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

/**
 * 登录切片
 */
export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setLoginCountdown: (state, action: PayloadAction<number>) => {
      state.loginCountdown = action.payload;
      if (action.payload > 0) {
        storage.set(LOGIN_COUNTDOWN_KEY, Date.now() + action.payload * 1000);
      } else {
        storage.remove(LOGIN_COUNTDOWN_KEY);
      }
    },
    setRegisterCountdown: (state, action: PayloadAction<number>) => {
      state.registerCountdown = action.payload;
      if (action.payload > 0) {
        storage.set(REGISTER_COUNTDOWN_KEY, Date.now() + action.payload * 1000);
      } else {
        storage.remove(REGISTER_COUNTDOWN_KEY);
      }
    },
    initCountdowns: (state) => {
      const loginEndTime = storage.get(LOGIN_COUNTDOWN_KEY);
      if (loginEndTime) {
        const remainingTime = Math.round((loginEndTime - Date.now()) / 1000);
        if (remainingTime > 0) {
          state.loginCountdown = remainingTime;
        } else {
          storage.remove(LOGIN_COUNTDOWN_KEY);
        }
      }
      const registerEndTime = storage.get(REGISTER_COUNTDOWN_KEY);
      if (registerEndTime) {
        const remainingTime = Math.round((registerEndTime - Date.now()) / 1000);
        if (remainingTime > 0) {
          state.registerCountdown = remainingTime;
        } else {
          storage.remove(REGISTER_COUNTDOWN_KEY);
        }
      }
    },
    logout: (state) => {
      state.token = null;
      state.loading = false;
      state.error = null;
      storage.remove(TOKEN_KEY);
    },
    restoreToken: (state) => {
      const token = storage.get(TOKEN_KEY);
      if (token) {
        state.token = token;
      } else {
        state.token = null;
        state.loading = false;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        console.log("这是什么", action);
        state.loading = false;
        state.token = action.payload;
        storage.set(TOKEN_KEY, action.payload);
      })
      .addCase(login.rejected, (state, action) => {
        console.log("这是什么2", action);
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(sendCode.fulfilled, (state, action) => {
        const isLogin = action.meta.arg.isLogin;
        if (isLogin) {
          state.loginCountdown = 60;
          storage.set(LOGIN_COUNTDOWN_KEY, Date.now() + 60 * 1000);
        } else {
          state.registerCountdown = 60;
          storage.set(REGISTER_COUNTDOWN_KEY, Date.now() + 60 * 1000);
        }
      })
      .addCase(sendCode.rejected, (state, action) => {
        state.error = action.payload as string;
      })
      .addCase(register.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.loading = false;
        const message = action.payload as string;
        if (message === "success") {
          console.log("注册成功");
        }
      })
      .addCase(register.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(getInviteCode.fulfilled, (state, action) => {
        state.inviteCode = action.payload as InviteCode;
      })
      .addCase(getInviteCode.rejected, (state, action) => {
        state.error = action.payload as string;
      })
      .addCase(getInvitedUsers.fulfilled, (state, action) => {
        state.invitedUsers = action.payload as InvitedUser[];
      })
      .addCase(getInvitedUsers.rejected, (state, action) => {
        state.error = action.payload as string;
      });
  },
});

export const {
  setLoginCountdown,
  setRegisterCountdown,
  initCountdowns,
  logout,
  restoreToken,
} = loginSlice.actions;

export default loginSlice.reducer;
